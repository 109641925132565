import introJs from 'intro.js';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

import animationVideo from './soundBox/assets/animation.mp4';
import 'intro.js/introjs.css';
import './intro.scss';

const Tour = ({ run, onClose }) => {
  useEffect(() => {
    if (run) {
      const intro = introJs();

      intro.setOptions({
        steps: [
          {
            element: document.querySelector('.timelineContainer'),
            intro:
              'This is where all your video and audio tracks are. Drag over the timeline to see the magic happen 🪄' +
              '<div style="text-align: center; margin-top: 10px; margin-bottom: 10px;"></div>' +
              '<video src="' +
              animationVideo +
              '" autoplay loop muted width="100%" height="100%" onloadedmetadata="this.currentTime=1"></video>',
          },
          {
            element: document.querySelector('.soundBoxContainer'),
            intro:
              'After you drag and prompt, wait a bit to see, or listen to your vision come to life  🔊',
            position: 'right',
          },
          {
            element: document.querySelector('.videoContainer'),
            intro: 'Watch your video in real-time 👀',
          },
        ],
        exitOnEsc: true,
        exitOnOverlayClick: false,
        showBullets: true,
        showStepNumbers: true,
        skipLabel: 'Skip',
        doneLabel: 'Done',
      });

      // Track the start of the tutorial
      mixpanel.track('Tutorial Started');

      intro.start();

      // Track the completion of each step
      intro.onafterchange(function () {
        const stepNumber = this._currentStep + 1;
        mixpanel.track('Tutorial Step Completed', {
          step: stepNumber,
          totalSteps: this._options.steps.length,
        });
      });

      // Track the exit point if the user skips the tutorial
      intro.onbeforeexit(function () {
        const stepNumber = this._currentStep + 1;
        mixpanel.track('Tutorial Exited', {
          exitStep: stepNumber,
          totalSteps: this._options.steps.length,
        });
      });

      // Track the completion of the tutorial
      intro.oncomplete(function () {
        mixpanel.track('Tutorial Completed');
        onClose();
      });
    }
  }, [run, onClose]);

  return null;
};

export default Tour;
