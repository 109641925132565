import mixpanel from 'mixpanel-browser';
import React, { useState, useEffect } from 'react';

import { useVideoContext } from '../context';
import { SoundContainer } from '../soundBox/container';
import ProgressBar from '../soundBox/progress_bar';

import { callMusicResult, callStartMusicGeneration, callMusicSuggestion } from './api';
import { MusicModal } from './musicModal';
import './musicBox.scss';

export const MusicBox: React.FC = () => {
  const [prompt, setPrompt] = useState('');
  const [isSongLoading, setIsSongLoading] = useState(false);
  const [isSuggestionLoading, setIsSuggestionLoading] = useState(false);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState<number | null>(null);
  const [files, setFiles] = useState([]);
  const [selectedSong, setSelectedSong] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { projectId } = useVideoContext();

  const handleVoiceTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPrompt(e.target.value);
  };

  const generateMusic = async () => {
    if (prompt.length === 0) {
      console.log('"Generate" pressed without any characters in textbox - should never happen');
      return;
    }
    setIsSongLoading(true);
    mixpanel.track('Generate Music', {
      category: 'Music',
      type: 'Generate Music',
      prompt: prompt,
    });
    callStartMusicGeneration(prompt, projectId).then((retrievalId: number) => {
      retrieveMusicTillComplete(retrievalId);
    });
  };

  const retrieveMusicTillComplete = async (retrievalId: number): void => {
    const responseData: Record<string, any> = await callMusicResult(retrievalId);

    if (!responseData.complete) {
      await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 3 seconds before retrying
      return retrieveMusicTillComplete(retrievalId); // Recursive call
    }

    setFiles((currentFiles) => {
      return [...responseData['files'], ...currentFiles];
    });
    setIsSongLoading(false);
  };

  useEffect(() => {
    setIsSuggestionLoading(true);
    callMusicSuggestion(projectId).then((responseData: Record<string, any>) => {
      console.log(responseData);
      const suggestion = responseData['suggestion'];
      if (suggestion) {
        setPrompt(suggestion);
      }
      setIsSuggestionLoading(false);
    });
  }, [projectId]);

  const isGenerateButtonDisabled = isSongLoading || prompt.length === 0;

  const openModal = (song) => {
    setSelectedSong(song);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedSong(null);
    setIsModalOpen(false);
  };

  const addSongToTimeline = (song) => {
    openModal(song);
    mixpanel.track('Open Add Song Modal', {
      category: 'Music',
      type: 'Open Add Song Modal',
      song: song.id,
    });
  };

  return (
    <div className="musicBox">
      <div className="column promptSection">
        <h3>Music Generation</h3>
        {isSuggestionLoading ? (
          <>
            <br />
            <br />
            <ProgressBar expectedDuration={6} isCompleted={false} />
            <p className="text-center text-black">Generating song suggestion for this video</p>
          </>
        ) : (
          <>
            <div className="voiceTextArea">
              <textarea
                value={prompt}
                onChange={handleVoiceTextChange}
                placeholder="Enter music prompt"
              ></textarea>
            </div>
            <button onClick={generateMusic} disabled={isGenerateButtonDisabled}>
              {isSongLoading ? 'Generating...' : 'Generate'}
            </button>
          </>
        )}
      </div>
      <div className="column resultSection">
        <h3>Songs</h3>
        <div>{isSongLoading && <ProgressBar expectedDuration={75} isCompleted={false} />}</div>
        <div className="soundHeader p0">
          {files.map(({ id, url, duration }) => (
            <SoundContainer
              key={id}
              url={url}
              index={id}
              onAddToTimeline={() => addSongToTimeline({ url, id, duration })}
              currentPlayingIndex={currentPlayingIndex}
              setCurrentPlayingIndex={setCurrentPlayingIndex}
            />
          ))}
        </div>
      </div>
      {selectedSong && (
        <MusicModal isOpen={isModalOpen} onClose={closeModal} song={selectedSong} prompt={prompt} />
      )}
    </div>
  );
};

export default MusicBox;
