// src/pages/editor/musicModal.tsx
import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js';

import { useVideoContext } from '../context';

import pauseIcon from './assets/pause.png';
import playIcon from './assets/play.png';
import './musicModal.scss';

interface MusicModalProps {
  isOpen: boolean;
  onClose: () => void;
  song: {
    url: string;
    id: number;
    duration: number;
  };
  prompt: string;
}

export const MusicModal: React.FC<MusicModalProps> = ({ isOpen, onClose, song, prompt }) => {
  const waveSurferRef = useRef<WaveSurfer | null>(null);
  const waveformContainerRef = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const { videoRef, addNewAudio } = useVideoContext();
  const videoDuration = videoRef.current.duration;
  const [regionStart, setRegionStart] = useState(0);
  const [regionEnd, setRegionEnd] = useState(videoDuration);

  const handleAddToTimeline = () => {
    addNewAudio({
      url: song.url,
      id: song.id,
      type: 'music',
      duration: song.duration,
      text: prompt,
      srcStart: regionStart,
      srcEnd: regionEnd,
    });
    onClose();
  };

  useEffect(() => {
    if (song.url && waveformContainerRef.current) {
      const waveSurfer = WaveSurfer.create({
        container: waveformContainerRef.current,
        cursorWidth: 1,
        height: 30,
        progressColor: '#cb7e97',
        waveColor: '#b0b0b0',
        responsive: true,
        normalize: true,
        barWidth: 1,
        barRadius: 2,
        fillParent: true,
      });

      const wsRegions = waveSurfer.registerPlugin(RegionsPlugin.create());

      waveSurfer.load(song.url);
      waveSurfer.on('ready', () => {
        wsRegions.addRegion({
          start: 0,
          end: videoDuration,
          color: 'hsl(206deg 100% 73.3% / 20%)',
          resize: true,
          drag: true,
          minLength: 0,
          maxLength: videoDuration,
        });
      });

      wsRegions.on('region-updated', (region) => {
        setRegionStart(region.start);
        setRegionEnd(region.end);
      });

      waveSurfer.on('play', () => setIsPlaying(true));
      waveSurfer.on('pause', () => setIsPlaying(false));
      waveSurferRef.current = waveSurfer;
    }

    return () => {
      waveSurferRef.current?.destroy();
    };
  }, [song.url, videoDuration]);

  useEffect(() => {
    console.log('Region now ' + regionStart + ' - ' + regionEnd);
  }, [regionStart, regionEnd]);

  const togglePlayPause = () => {
    waveSurferRef.current?.playPause();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="music-modal">
      <div className="overlay" onClick={onClose}></div>
      <div className="modal-content">
        <h2>Add Music to Timeline</h2>
        <div className="sound-container">
          <div className="soundContainer">
            <div className="playButton" onClick={togglePlayPause}>
              <img src={isPlaying ? pauseIcon : playIcon} alt="Play/Pause" />
            </div>
            <div className="waveformContainer" ref={waveformContainerRef}></div>
          </div>
        </div>
        <div className="actions">
          <button className="cancel" onClick={onClose}>
            Cancel
          </button>
          <button className="add" onClick={handleAddToTimeline}>
            Add to Timeline
          </button>
        </div>
      </div>
    </div>
  );
};
