// LoginPage.tsx
import { useAuth0 } from '@auth0/auth0-react';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import './login.scss';

export const LoginPage: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    mixpanel.track('Visited Login Page', {
      category: 'User',
    });

    loginWithRedirect(); // Automatically redirect to Auth0 login on component mount
  }, [loginWithRedirect]);

  return null; // Assuming your component does not render anything itself
};

export default LoginPage;
