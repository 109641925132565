import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';

import audioControl from './audioControl';
import { PromptBar } from './promptPopup';

export const scale = 10;
export const scaleWidth = 320;
export const startLeft = 20;

const controls = {
  start: ({ action, engine, isPlaying, time }) => {
    console.log('starting audio ' + action.data.src);
    if (isPlaying && action.start <= time && action.end > time) {
      audioControl.start({
        id: action.id,
        src: action.data.src,
        srcStart: action.data.srcStart,
        srcEnd: action.data.srcEnd,
        startTime: action.start,
        engine: engine,
        time: time,
      });
    }
  },
  enter: ({ action, engine, isPlaying, time }) => {
    console.log('entering audio ' + action.data.src);
    if (isPlaying) {
      audioControl.start({
        id: action.id,
        src: action.data.src,
        startTime: action.start,
        srcStart: action.data.srcStart,
        srcEnd: action.data.srcEnd,
        engine: engine,
        time: time,
      });
    }
  },
  leave: ({ action }) => {
    audioControl.stop(action.id);
  },
  stop: ({ action }) => {
    audioControl.stop(action.id);
  },
};

export const ribbonTypeMap = {
  video: {
    id: 'video',
    name: 'Play video',
  },
  audio: {
    id: 'audio',
    name: 'Play audio',
    source: controls,
  },
  voice: {
    id: 'voice',
    name: 'Play voice',
    source: controls,
  },
  music: {
    id: 'music',
    name: 'Play music',
    source: controls,
  },
};

const RenderVideoRibbon = () => {
  return (
    <div className="ribbon-text video-ribbon">
      <div className="no-audio-text">
        <b>[ ORIGINAL AUDIO TRACK ]</b>
      </div>
    </div>
  );
};

export default RenderVideoRibbon;

const truncate = (text: string) => {
  // truncates text at 7 words
  let truncatedText = text.split(' ').slice(0, 7).join(' ');
  if (text.split(' ').length > 7) {
    truncatedText = truncatedText + '...';
  }
  return truncatedText;
};

const RenderAudioRibbon = ({ action, selectedId }) => {
  const isSelected = action.id === selectedId;
  const [soundDuration, setSoundDuration] = useState(action.end - action.start);
  const [actionDuration, setActionDuration] = useState(action.end - action.start);
  const [nrWaves, setNrWaves] = useState(1);
  const waveformRefs = useRef([]);
  const ribbonDivRef = useRef(null);
  const [pixelsPerSecond, setPixelsPerSecond] = useState(100);

  useEffect(() => {
    setActionDuration(action.end - action.start);
  }, [action.start, action.end]);

  useEffect(() => {
    // only set nrWaves if we're adding more waves, otherwise don't force a rerender
    setNrWaves((currentNrWaves) => {
      const newNrWaves = Math.ceil(actionDuration / soundDuration);
      if (currentNrWaves < newNrWaves) {
        return newNrWaves;
      } else {
        return currentNrWaves;
      }
    });
  }, [actionDuration, soundDuration]);

  useEffect(() => {
    if (ribbonDivRef.current) {
      const ribbonTextWidth = ribbonDivRef.current.getBoundingClientRect().width;
      const newPixelsPerSecond = ribbonTextWidth / actionDuration;
      // don't constantly change the width at every little drag
      if (
        newPixelsPerSecond / pixelsPerSecond > 1.02 ||
        newPixelsPerSecond / pixelsPerSecond < 0.98
      ) {
        setPixelsPerSecond(newPixelsPerSecond);
        console.log('pixels per second: ' + newPixelsPerSecond);
      }
    }
  }, [ribbonDivRef, actionDuration, pixelsPerSecond]);

  useEffect(() => {
    const newWavesurfers = waveformRefs.current.map((ref) => {
      if (ref) {
        const wavesurfer = WaveSurfer.create({
          container: ref,
          barWidth: 1.5,
          barRadius: 3,
          cursorWidth: 0,
          height: 20,
          barGap: 1.5,
          responsive: true,
          normalize: true,
          progressColor: '#6e6e6e',
          waveColor: '#6e6e6e',
        });

        // Load audio file
        // Load the audio source
        console.log('loading audio source:', action.data.src);
        wavesurfer.load(action.data.src).catch((error) => {
          // this seems to be some WEIRD CORS error. Only happens to voice and only happens the first time
          // - doesn't happen when the project is reloaded (and the presigned URL is thus regenerated)
          console.log('Error while loading ' + action.effectId + ' waveform: ' + error);
        });
        wavesurfer.on('ready', () => setSoundDuration(wavesurfer.getDuration()));

        return wavesurfer;
      }
      return null;
    });

    // Clean up wavesurfer instances on component unmount
    return () => {
      newWavesurfers.forEach((wavesurfer) => {
        if (wavesurfer) {
          wavesurfer.destroy();
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action.data.src, nrWaves]);

  const addWaveformRef = (ref) => {
    if (ref && !waveformRefs.current.includes(ref)) {
      waveformRefs.current.push(ref);
    }
  };

  let icon = 'graphic_eq';
  if (action.effectId === 'voice') {
    icon = 'mic';
  } else if (action.effectId === 'music') {
    icon = 'music_note';
  }

  let text = action.data.text;
  // this happens on old projects
  if (text) {
    text = truncate(text);
    if (action.effectId === 'voice') {
      text = '"' + text + '"';
    }
  }

  return (
    <>
      <div
        className={`ribbon-text audio-ribbon ${action.effectId}-ribbon ${
          isSelected ? 'selected' : ''
        }`}
        data-delta-tracks={action.relY}
        data-action-id={action.id}
        ref={ribbonDivRef}
      >
        <div style={{ display: 'inline-block' }}>
          {[...Array(nrWaves)].map((_, index) => (
            <div
              key={index}
              className="waveform"
              ref={addWaveformRef}
              style={{ width: pixelsPerSecond * soundDuration + 'px' }}
            />
          ))}
        </div>
      </div>
      {text && (
        <div className="ribbon-tooltip">
          <p>
            <span className="material-symbols-outlined">{icon}</span>
            {text}
          </p>
          <div className="arrow-down"></div>
        </div>
      )}
    </>
  );
};

const RenderPlaceholderRibbon = () => {
  return <div className="ribbon-text placeholder-ribbon"></div>;
};

const RenderPromptRibbon = ({ action, row }) => {
  // todo: structure me right, with a flexbox
  const showBelow = row.id < 2;

  if (action.start >= action.end) {
    return <div className="ribbon-text placeholder-ribbon"></div>;
  }

  return (
    <>
      <div className="ribbon-text prompt-ribbon"></div>
      <PromptBar showBelow={showBelow} />
    </>
  );
};

export const RenderRibbon = ({ action, row, selectedId }) => {
  action.relY = action.relY || 0; // this prevents "undefined" being handled as a truthy string

  if (action.effectId === 'video') {
    return <RenderVideoRibbon action={action} row={row} />;
  } else if (action.effectId === 'audio') {
    return <RenderAudioRibbon action={action} row={row} selectedId={selectedId} />;
  } else if (action.effectId === 'voice') {
    return <RenderAudioRibbon action={action} row={row} selectedId={selectedId} />;
  } else if (action.effectId === 'music') {
    return <RenderAudioRibbon action={action} row={row} selectedId={selectedId} />;
  } else if (action.effectId === 'placeholder') {
    return <RenderPlaceholderRibbon action={action} />;
  } else if (action.effectId === 'prompt') {
    return <RenderPromptRibbon action={action} row={row} />;
  }
};
