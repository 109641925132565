import React, { useEffect, useState } from 'react';
import './progress_bar.scss';

interface ProgressBarProps {
  startLoading: boolean;
  onComplete: () => void;
  uploadDuration: number; // Add this prop to receive the calculated upload duration
}

const ProgressBar: React.FC<ProgressBarProps> = ({ startLoading, onComplete, uploadDuration }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!startLoading || uploadDuration <= 0) {
      setProgress(0);
      return;
    }

    // Adjust the interval timing based on the uploadDuration
    const totalDurationMs = uploadDuration * 1000; // Convert seconds to milliseconds
    const incrementTimeMs = totalDurationMs / 100; // Dynamically adjust increment time

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        let newIncrement = 1;
        // Slower start
        if (prevProgress < 30) {
          newIncrement = 0.7;
        }
        // Faster in the middle
        else if (prevProgress >= 30 && prevProgress < 60) {
          newIncrement = 1.3;
        }
        // Slower at the end
        else if (prevProgress >= 80) {
          // becomes slower and slower
          const slow_factor = prevProgress - 79;
          newIncrement = 0.75 / slow_factor;
        }

        const nextProgress = prevProgress + newIncrement;

        if (nextProgress >= 99) {
          return 99;
        }

        return nextProgress;
      });
    }, incrementTimeMs); // Use the dynamically adjusted interval time

    return () => clearInterval(interval);
  }, [startLoading, onComplete, uploadDuration]); // Include uploadDuration in the dependency array

  return (
    <div className="progress" id="progressBar">
      <div
        className="progress-number"
        aria-valuenow={Math.round(progress)}
        style={{ left: `calc(${progress}% - ${progress === 99 ? '20px' : '10px'})` }}
      ></div>
      <div className="rounded">
        <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
};

export default ProgressBar;
