import { Howl } from 'howler';

class AudioControl {
  constructor() {
    this.audioMap = {};
    console.log('AudioControl initialized');
  }

  load(data) {
    // Extracting action details
    const { id, src, startTime, endTime } = data;

    // Prepare the data to be sent
    const audioData = {
      id: id,
      src: src,
      startTime: startTime,
      endTime: endTime,
    };

    console.log('AudioControl called with data:', audioData);

    // Throw an error if the source is not provided
    if (!src) {
      throw new Error('src parameter unfilled');
    }

    // Check if the audio is already loaded in the cache
    if (this.audioMap[id]) {
      // Unload and delete the old Howl instance if the source has changed
      if (src && this.audioMap[id]._src !== src) {
        console.log('Source has changed. Unloading old audio for id:', id, '. Loading src:', src);
        this.audioMap[id].unload();
        delete this.audioMap[id];
      }
    }

    // Only create a new Howl instance if it doesn't exist or has been deleted
    if (!this.audioMap[id]) {
      console.log('Creating new Howl instance - id:', id, 'src:', src);
      this.audioMap[id] = new Howl({
        src: [src], // Ensure the src is set as an array
        autoplay: false,
        loop: true,
      });

      // non-functional, just for logging
      this.audioMap[id].on('load', () => {
        console.log(`Audio loaded for id: ${id} with source: ${src}.`);
      });
      this.audioMap[id].on('loaderror', (errId, error) => {
        console.error(`Load error for id: ${id}. Error:`, error);
      });
      this.audioMap[id].on('playerror', (errId, error) => {
        console.error(`Play error for id: ${id}. Error:`, error);
      });
    }
  }

  start(data) {
    this.load(data); // we should be able to avoid this call (edge case: undo-ing)
    const { id, time, startTime, srcStart } = data;
    const item = this.audioMap[id];
    item.seek(time - startTime + (srcStart || 0));
    item.play();
  }

  stop(id) {
    // todo: refactor this if out, let it fail hard, prevent it from being called
    const item = this.audioMap[id];
    if (item) {
      item.stop();
    }
  }

  mute(id) {
    console.log('Muting audio for id:', id);
    const audio = this.audioMap[id];
    if (audio) {
      audio._previousVolume = audio.volume();
      audio.volume(0);
      console.log(`Audio muted for id: ${id}`);
    }
  }

  unmute(id) {
    console.log('Unmuting audio for id:', id);
    const audio = this.audioMap[id];
    if (audio && audio._previousVolume !== undefined) {
      audio.volume(audio._previousVolume);
      console.log(`Audio unmuted for id: ${id}`);
    }
  }

  unload(id) {
    const audio = this.audioMap[id];
    if (audio) {
      audio.unload();
      delete this.audioMap[id];
      console.log(`Audio unloaded for id: ${id}`);
    }
  }

  setVolume(id, volume) {
    const audio = this.audioMap[id];
    console.log(this.audioMap);
    audio.volume(volume);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AudioControl();
