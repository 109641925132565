import mixpanel from 'mixpanel-browser';
import React, { FC, useState, useEffect } from 'react';

import { Head } from '@/components/Head';
import { hasSeenTour, setCookie } from '@/utils/cookies';

import { Header } from './header';
import { ModalityOptions } from './modalityOptions';
import { MusicBox } from './musicBox';
import { SoundBox } from './soundBox';
import { VideoEditor } from './timeline/VideoEditor';
import Tour from './Tour';
import { VideoPlayer } from './videoPlayer';
import { VoiceBox } from './voiceBox';
import './editor.scss';

export const EditorPage: FC = () => {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [selectedModality, setSelectedModalityRaw] = useState('sfx');
  const [sfxHasShow, setSfxHasShown] = useState(false);
  const [musicHasShow, setMusicHasShown] = useState(false);
  const [voiceHasShow, setVoiceHasShown] = useState(false);

  useEffect(() => {
    const hasSeenTourInSession = sessionStorage.getItem('tourSeen') === 'true';
    const hasSeenTourInCookie = hasSeenTour();

    setIsTourOpen(!hasSeenTourInSession && !hasSeenTourInCookie);
  }, []);

  const closeTour = () => {
    setIsTourOpen(false);
    sessionStorage.setItem('tourSeen', 'true');
    setCookie('tour', 'true', 365);
  };

  const toggleFeedbackForm = () => {
    mixpanel.track('Feedback Button Clicked');
    window.open('https://forms.gle/Rwpsr1kZkncR6fdr6', '_blank');
  };

  const setSelectedModality = (value: string) => {
    if (value === 'sfx') {
      setSfxHasShown(true);
    } else if (value === 'voice') {
      setVoiceHasShown(true);
    } else if (value === 'music') {
      console.log('setting music has show to true');
      setMusicHasShown(true);
    }

    setSelectedModalityRaw(value);
  };

  return (
    <>
      <Head />
      <Header showProjectControls={true} />
      <div className="feedback-button" id="FAQButton" onClick={toggleFeedbackForm}>
        <h4>Feedback</h4>
      </div>
      <div className="editorContainer">
        <div className="topSection">
          <div className="modalityOptionsContainer">
            <ModalityOptions onModalityClick={setSelectedModality} />
          </div>

          {(selectedModality === 'sfx' || sfxHasShow) && (
            <div
              className="soundBoxContainer"
              style={{ display: selectedModality === 'sfx' ? '' : 'none' }}
            >
              <SoundBox></SoundBox>
            </div>
          )}

          {(selectedModality === 'voice' || voiceHasShow) && (
            <div
              className="voiceBoxContainer"
              style={{ display: selectedModality === 'voice' ? '' : 'none' }}
            >
              <VoiceBox></VoiceBox>
            </div>
          )}

          {(selectedModality === 'music' || musicHasShow) && (
            <div
              className="voiceBoxContainer"
              style={{ display: selectedModality === 'music' ? '' : 'none' }}
            >
              <MusicBox></MusicBox>
            </div>
          )}

          <div className="videoContainer">
            <VideoPlayer />
          </div>
        </div>
        <div className="timelineContainer">
          <VideoEditor />
        </div>
      </div>
      <Tour run={isTourOpen} onClose={closeTour} />
    </>
  );
};

export default EditorPage;
