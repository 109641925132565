// ProjectChatModal.tsx
import React, { useState, useRef, useEffect } from 'react';
import './projectChatModal.scss';

interface Comment {
  id: number;
  user: string;
  timestamp: number;
  text: string;
  replies: Comment[];
}

interface ProjectChatModalProps {
  onClose: () => void;
  comments: Comment[];
  setComments: (comments: Comment[]) => void;
}

const ProjectChatModal: React.FC<ProjectChatModalProps> = ({ onClose, comments, setComments }) => {
  const [newComment, setNewComment] = useState('');
  const [replyToCommentId, setReplyToCommentId] = useState<number | null>(null);
  const [editCommentId, setEditCommentId] = useState<number | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (newComment.trim()) {
      if (editCommentId) {
        setComments(comments.map((c) => (c.id === editCommentId ? { ...c, text: newComment } : c)));
        setEditCommentId(null);
      } else {
        const comment: Comment = {
          id: Date.now(),
          user: 'Current User',
          timestamp: Date.now(),
          text: newComment,
          replies: [],
        };
        if (replyToCommentId) {
          setComments(
            comments.map((c) =>
              c.id === replyToCommentId ? { ...c, replies: [...c.replies, comment] } : c
            )
          );
        } else {
          setComments([...comments, comment]);
        }
      }
      setNewComment('');
      setReplyToCommentId(null);
    }
  };

  const handleReply = (commentId: number) => {
    setReplyToCommentId(commentId);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleEdit = (commentId: number) => {
    const commentToEdit = comments.find((c) => c.id === commentId);
    if (commentToEdit) {
      setNewComment(commentToEdit.text);
      setEditCommentId(commentId);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const handleDelete = (commentId: number) => {
    setComments(comments.filter((c) => c.id !== commentId));
  };

  const formatTime = (timestamp: number) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  return (
    <div className="project-chat-modal-overlay" onClick={handleOverlayClick}>
      <div className="project-chat-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="chat-container">
          <div className="comment-list">
            {comments
              .sort((a, b) => a.timestamp - b.timestamp)
              .map((comment) => (
                <div key={comment.id} className="comment">
                  <div className="comment-content">
                    <div className="comment-header">
                      <div className="user-icon">
                        <i className="fas fa-user"></i>
                      </div>
                      <div className="comment-info">
                        <span className="comment-user">{comment.user}</span>
                        <span className="comment-timestamp">
                          Posted at: {formatTime(comment.timestamp)}
                        </span>
                      </div>
                    </div>
                    <div className="comment-text">
                      {comment.text}
                      <div className="comment-actions">
                        <button className="action-button" onClick={() => handleReply(comment.id)}>
                          <span className="material-symbols-outlined">chat</span>
                        </button>
                        <button className="action-button" onClick={() => handleEdit(comment.id)}>
                          <span className="material-symbols-outlined">edit</span>
                        </button>
                        <button className="action-button" onClick={() => handleDelete(comment.id)}>
                          <span className="material-symbols-outlined">delete</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {comment.replies.map((reply) => (
                    <div key={reply.id} className="reply">
                      <div className="comment-content">
                        <div className="comment-header">
                          <div className="user-icon">
                            <i className="fas fa-user"></i>
                          </div>
                          <div className="comment-info">
                            <span className="comment-user">{reply.user}</span>
                            <span className="comment-timestamp">
                              Posted at: {formatTime(reply.timestamp)}
                            </span>
                          </div>
                        </div>
                        <div className="comment-text">{reply.text}</div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
          </div>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              ref={inputRef}
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder={
                editCommentId
                  ? 'Edit comment...'
                  : replyToCommentId
                  ? 'Reply to comment...'
                  : 'Add a comment...'
              }
            />
            <button type="submit">{editCommentId ? 'Save' : 'Send'}</button>
            {(replyToCommentId || editCommentId) && (
              <button
                type="button"
                onClick={() => {
                  setReplyToCommentId(null);
                  setEditCommentId(null);
                }}
              >
                Cancel
              </button>
            )}
          </form>
        </div>
        <button className="close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ProjectChatModal;
