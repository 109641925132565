import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';

// Assuming these pages are already correctly imported
import { AccountPage } from '@/pages/account';
import { EditorPage } from '@/pages/editor';
import { LoginPage } from '@/pages/login';
import { StorylinePage } from '@/pages/storyline';
import { UploadPage } from '@/pages/videoUpload';

// Example of how you might include a protected route check directly in routing
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth0(); // Ensure useAuth0 hook is imported

  if (!isAuthenticated) {
    // Redirect to login page if not authenticated
    return <Navigate to="/login" />;
  }

  return children;
};

export const AppRoutes = () => {
  const element = useRoutes([
    {
      path: '/upload',
      element: (
        <ProtectedRoute>
          <UploadPage />
        </ProtectedRoute>
      ),
    },
    {
      path: '/app',
      element: (
        <ProtectedRoute>
          <EditorPage />
        </ProtectedRoute>
      ),
    },
    { path: '/login', element: <LoginPage /> },
    { path: '/storyline', element: <StorylinePage /> },
    { path: '/account', element: <AccountPage /> },
    { path: '/', element: <Navigate to="/upload" /> },
  ]);

  return <>{element}</>;
};
