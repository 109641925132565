// ModalityOptions.tsx
import React, { FC, useState } from 'react';
import './style.scss';

import logoEdits from './assets/edits.png';
import logoMusic from './assets/music.png';
import logoSFX from './assets/sfx.png';
import logoVideo from './assets/video.png';
import logoVoice from './assets/voice.png';

interface ModalityOptionsProps {
  onModalityClick: (modality: string) => void;
}

export const ModalityOptions: FC<ModalityOptionsProps> = ({ onModalityClick }) => {
  const [selectedModality, setSelectedModality] = useState('sfx');
  const [showPopup, setShowPopup] = useState(false);
  const [featureDescription, setFeatureDescription] = useState('This feature');

  const handleModalityClick = (modality: string) => {
    if (modality === 'voice') {
      setSelectedModality(modality);
      onModalityClick(modality);
    } else if (modality === 'sfx') {
      setSelectedModality(modality);
      onModalityClick(modality);
    } else if (modality === 'music') {
      setSelectedModality(modality);
      onModalityClick(modality);
    } else if (modality === 'video') {
      setShowPopup(true);
      setFeatureDescription('Video generation');
    } else if (modality === 'edits') {
      setShowPopup(true);
      setFeatureDescription('Advanced configuration');
    } else {
      throw new Error('Unknown modality ' + modality);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="modalityOptions">
      <img
        src={logoSFX}
        alt="SFX"
        title="SFX"
        className={`modalityLogo ${selectedModality === 'sfx' ? 'selected' : ''}`}
        onClick={() => handleModalityClick('sfx')}
      />
      <img
        src={logoVoice}
        alt="Voice"
        title="Voice"
        className={`modalityLogo ${selectedModality === 'voice' ? 'selected' : ''}`}
        onClick={() => handleModalityClick('voice')}
      />
      <img
        src={logoMusic}
        alt="Music"
        title="Music"
        className={`modalityLogo ${selectedModality === 'music' ? 'selected' : ''}`}
        onClick={() => handleModalityClick('music')}
      />
      <img
        src={logoVideo}
        alt="Video"
        title="Video"
        className={`modalityLogo ${selectedModality === 'video' ? 'selected' : ''}`}
        onClick={() => handleModalityClick('video')}
      />
      <img
        src={logoEdits}
        alt="Edits"
        className={`modalityLogo ${selectedModality === 'edits' ? 'selected' : ''}`}
        onClick={() => handleModalityClick('edits')}
      />
      {showPopup && (
        <div className="comingSoonPopup">
          <div className="popupContent">
            <h2>Coming Soon!</h2>
            <p>{featureDescription} is on our roadmap for the next few weeks.</p>
            <button className="closeButton" onClick={handleClosePopup}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
