// utils.js
import axios from 'axios';
import mixpanel from 'mixpanel-browser';

import { API_URL } from '@/config';
import storage from '@/utils/storage';

// todo: refactor out
import { getConfig } from '../../config/config';

const { apiConfig, mixPanelToken } = getConfig();
const API_CONFIG = apiConfig;

// Initialize Google Analytics
mixpanel.init(mixPanelToken, {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
});
/**
 * Get upload parameters for an S3 file upload
 * @param {string} fileName The name of the file to upload.
 * @returns {Promise} Axios promise with the upload parameters.
 */
export const getUploadParams = (fileName: string) => {
  return axios.post(
    `${API_URL}/api/get_upload_url/`,
    { file_name: fileName, user: storage.getEmail() },
    API_CONFIG
  );
};

/**
 * Uploads a video file to S3 and returns the upload details.
 * @param {{ data: { url: string; fields: Record<string, string>; id: string; download_url: string; }, file: File }} params Parameters for S3 upload.
 * @returns {Promise} A promise that resolves with the upload details including fileId and downloadUrl.
 */
export const uploadToS3 = ({
  data,
  file,
}: {
  data: { url: string; fields: Record<string, string>; id: number; download_url: string };
  file: File;
}) => {
  const postData = new FormData();
  Object.keys(data.fields).forEach((key) => {
    postData.append(key, data.fields[key]);
  });
  postData.append('file', file);
  return axios
    .post(data.url, postData)
    .then(() => Promise.resolve({ fileId: data.id, downloadUrl: data.download_url }));
};

export const setUploadFinished = (video_id: number) => {
  return axios.post(
    `${API_URL}/api/upload_finished/`,
    { video_id: video_id },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const loadProjectEndpoint = (projectId: number) => {
  return axios.post(
    `${API_URL}/api/get_project/`,
    { project_id: projectId },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const callDeleteProject = (projectId: number) => {
  return axios.post(
    `${API_URL}/api/delete_project/`,
    { project_id: projectId, user: storage.getEmail() },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};
