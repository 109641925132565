// src/pages/editor/context.tsx
import mixpanel from 'mixpanel-browser';
import React, { createContext, useContext, useState, useEffect, useRef } from 'react';

import { DEFAULT_VIDEO_URL, DEFAULT_VIDEO_ID, DEFAULT_PROJECT_ID } from '@/config';
import { saveProjectEndpoint } from '@/pages/editor/header/api';

import audioControl from './timeline/audioControl';

interface Action {
  id: number;
  effectId: string;
  start: number;
  end: number;
  flexible: boolean;
  movable: boolean;
  data: {
    src: string;
    name: string;
  };
}

interface DataRow {
  actions: Action[];
}

// SFX
interface Sound {
  url: string;
  text: string;
  id: number;
}

// voice or music
interface Audio {
  url: string;
  text: string;
  id: number;
  duration: number;
  type: string;
  srcStart: number | null;
  srcEnd: number | null;
}

interface ProjectPackage {
  data: DataRow[];
  isMuted: Record<number, any>;
  isLocked: Record<number, any>;
  volumes: Record<number, any>;
}

export interface ActionCoords {
  action_i: number | null;
  row_i: number | null;
}

interface VideoContextType {
  videoRef: React.RefObject<HTMLVideoElement>;
  videoId: number | null;
  videoURL: string;
  setVideoInfo: (id: number, url: string) => void;
  data: DataRow[];
  setData: React.Dispatch<React.SetStateAction<DataRow[]>>;
  prompt: string;
  setPrompt: React.Dispatch<React.SetStateAction<string>>;
  selectedCoords: ActionCoords;
  setSelectedCoords: React.Dispatch<React.SetStateAction<ActionCoords>>;
  addNewSound: (sound: Sound) => void;
  addNewAudio: (audio: Audio) => void;
  removeSound: (actionId: number, row_i: number) => void;
  runId: number;
  setRunId: React.Dispatch<React.SetStateAction<number>>;
  undo: () => void;
  redo: () => void;
  setInitialData: () => void;
  PLACEHOLDERS_PER_SECOND: number;
  resetState: () => void;
  tStartSoundBox: number;
  setTStartSoundBox: React.Dispatch<React.SetStateAction<number>>;
  tEndSoundBox: number;
  setTEndSoundBox: React.Dispatch<React.SetStateAction<number>>;
  projectId: number;
  setProjectId: React.Dispatch<React.SetStateAction<number>>;
  projectName: string;
  setProjectName: React.Dispatch<React.SetStateAction<string>>;
  isLocked: Record<number, any>;
  setIsLocked: React.Dispatch<React.SetStateAction<Record<number, any>>>;
  isMuted: Record<number, any>;
  setIsMuted: React.Dispatch<React.SetStateAction<Record<number, any>>>;
  volumes: Record<number, any>;
  setVolumes: React.Dispatch<React.SetStateAction<Record<number, any>>>;
  packageProject: () => ProjectPackage;
  loadProjectPackage: (projectPackage: ProjectPackage) => void;
}

const VideoContext = createContext<VideoContextType | undefined>(undefined);

export const VideoProvider: React.FC = ({ children }) => {
  const videoRef = useRef(null);
  const [videoId, setVideoId] = useState<number | null>(null);
  const [videoURL, setVideoURL] = useState<string>('');
  const [data, setDataRaw] = useState<DataRow[]>([]);
  const [prompt, setPrompt] = useState<string | null>(null);
  const [runId, setRunId] = useState<number>(0);
  const [selectedCoords, setSelectedCoords] = useState<ActionCoords>({
    action_i: null,
    row_i: null,
  });
  const [backwardData, setBackwardData] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [forwardData, setForwardData] = useState<any[]>([]);
  const [tStartSoundBox, setTStartSoundBox] = useState<number>(0);
  const [tEndSoundBox, setTEndSoundBox] = useState<number>(0);
  const [projectId, setProjectId] = useState<number | null>(null);
  const [projectName, setProjectName] = useState<string>('');
  const [isLocked, setIsLocked] = useState({ 0: true });
  const [isMuted, setIsMuted] = useState({});
  const [volumes, setVolumes] = useState({});

  const resetState = () => {
    setVideoId(null);
    setVideoURL('');
    setDataRaw([]);
    setPrompt(null);
    setRunId(0);
    setSelectedCoords({ action_i: null, row_i: null });
    setBackwardData([]);
    setForwardData([]);
    // setInitialData();  // this was recently removed because it was causing bugs - works in all tested use cases
    // if still present at the end of August, delete line altogether
  };

  useEffect(() => {
    mixpanel.track('Page View', { path: window.location.pathname + window.location.search });
  }, []);

  const setData = (newData: DataRow[] | null) => {
    // todo: limit size
    // todo: detect change and decide whether to include it in the backwardData
    // todo: experiment with removing all the json parsing

    if (typeof newData === 'function') {
      setDataRaw((oldData) => {
        newData = newData(oldData);
        return newData;
      });
    }

    if (typeof newData === 'function') {
      throw Error('newData cannot be of type function');
    }
    setBackwardData((backwardData) => {
      if (!backwardData.length || dataUnique(backwardData[0], newData)) {
        return [JSON.parse(JSON.stringify(newData)), ...backwardData];
      } else {
        return backwardData;
      }
    });
    console.log('Set data, length of backwardData:', backwardData.length);

    setDataRaw(newData);
  };

  // autosave
  useEffect(() => {
    // don't save if project doesn't have a name
    if (!projectId || !projectName || !data.length) {
      return;
    }
    const projectPackage = {
      data: data,
      isMuted: isMuted,
      isLocked: isLocked,
      volumes: volumes,
    };
    saveProjectEndpoint(projectId, projectPackage, projectName);
  }, [projectId, projectName, data, isMuted, isLocked, volumes]);

  const getAudioActions = (currentData: any[]) => {
    const actions = [];
    for (const row of currentData) {
      for (const action of row.actions) {
        if (action.effectId !== 'placeholder' && action.effectId !== 'video') {
          actions.push(action);
        }
      }
    }
    return actions;
  };

  const dataUnique = (oldData: any[], newData: any[]) => {
    if (!oldData || !newData) {
      return true;
    }
    const oldActions = getAudioActions(oldData);
    const newActions = getAudioActions(newData);
    return JSON.stringify(oldActions) !== JSON.stringify(newActions);
  };

  const undo = () => {
    setBackwardData((backwardData) => {
      if (backwardData.length > 1) {
        console.log('backwardData is length ' + backwardData.length + ' - undoing.');
        setDataRaw(backwardData[1]);

        // for some reason this whole function gets called multiple times - this deduplicates
        setForwardData((forwardData: any[]) => {
          if (dataUnique(backwardData[0], forwardData[0])) {
            return [backwardData[0], ...forwardData];
          } else {
            return forwardData;
          }
        });

        return backwardData.slice(1);
      } else {
        console.log('backwardData is length ' + backwardData.length + ' - nothing to undo.');
        return backwardData;
      }
    });
  };

  const redo = () => {
    setForwardData((forwardData) => {
      if (forwardData.length > 0) {
        console.log('forwardData is length ' + forwardData.length + ' - redoing.');
        setDataRaw(forwardData[0]);
        // todo: test if deep copy necessary

        // for some reason this whole function gets called multiple times - this deduplicates
        setBackwardData((backwardData: any[]) => {
          if (dataUnique(backwardData[0], forwardData[0])) {
            return [forwardData[0], ...backwardData];
          } else {
            return backwardData;
          }
        });

        return forwardData.slice(1);
      } else {
        console.log('forwardData is length ' + forwardData.length + ' - nothing to redo.');
        return forwardData;
      }
    });
  };

  const makeVideoRow = () => {
    console.log('setVideoRibbon fired.');
    if (!videoId) {
      throw new Error('videoId cannot be ' + videoId);
    }
    if (!videoRef.current.src) {
      throw new Error('videoRef.current.src cannot be ' + videoRef.current.src);
    }
    return [
      {
        id: 0,
        actions: [
          {
            id: videoId,
            effectId: 'video',
            start: 0,
            end: videoRef.current.duration,
            flexible: false,
            movable: false,
            data: {
              src: videoRef.current.src,
              name: 'video',
            },
          },
        ],
      },
    ];
  };

  const PLACEHOLDERS_PER_SECOND = 8;
  const addRow = () => {
    const PLACEHOLDERS_PER_ROW = (videoRef.current.duration * PLACEHOLDERS_PER_SECOND).toFixed();

    setDataRaw((currentData) => {
      const row_id = currentData.length;

      const row = {
        id: row_id,
        actions: [],
      };

      for (let action_id = 0; action_id < PLACEHOLDERS_PER_ROW; action_id++) {
        const action = {
          id: -(PLACEHOLDERS_PER_ROW * row_id + action_id), // we make this negative because we don't want to interfere with actual data
          effectId: 'placeholder',
          start: action_id / PLACEHOLDERS_PER_SECOND,
          end: action_id / PLACEHOLDERS_PER_SECOND + 1 / PLACEHOLDERS_PER_SECOND,
          maxEnd: videoRef.current.duration,
        };
        row.actions.push(action);
      }
      currentData = [...currentData, row];
      return currentData;
    });
  };

  const setInitialData = () => {
    if (data.length) {
      // don't continue if there is already data present
      return;
    }
    setDataRaw(makeVideoRow());
    const TOTAL_ROWS = 6;
    for (let i = 0; i < TOTAL_ROWS; i++) {
      addRow();
    }
  };

  useEffect(() => {
    if (!videoRef.current || !videoId) {
      return;
    }

    if (videoRef.current.readyState >= 1) {
      // Metadata has already been loaded
      setInitialData();
    } else {
      // Metadata has not been loaded yet
      videoRef.current.addEventListener('loadedmetadata', setInitialData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef.current, videoId]);

  const setVideoInfo = (id: number, url: string) => {
    console.log(`Setting video info with id: ${id}, url: ${url}`);
    setVideoId(id);
    setVideoURL(url);
    mixpanel.people.set({ dimension1: id.toString() });
    mixpanel.track('Set Video Info', {
      category: 'Video',
      id,
      url,
    });
  };

  if (!videoURL && DEFAULT_VIDEO_URL) {
    setVideoURL(DEFAULT_VIDEO_URL);
  }

  if (!videoId && DEFAULT_VIDEO_ID) {
    setVideoId(DEFAULT_VIDEO_ID);
  }

  if (!projectId && DEFAULT_PROJECT_ID) {
    setProjectId(DEFAULT_PROJECT_ID);
  }

  function getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function assignActionId(fileId: number): number {
    return fileId * 10000 + getRandomInt(0, 10000);
  }

  const addNewSound = (sound: Sound) => {
    if (selectedCoords.row_i !== null && selectedCoords.action_i !== null) {
      console.log('Adding sound to timeline: ' + sound.text);
      const newId = sound.id;
      const oldAction = data[selectedCoords.row_i]?.actions[selectedCoords.action_i];
      const newAction = {
        id: assignActionId(newId),
        effectId: 'audio',
        start: oldAction.start,
        end: oldAction.end,
        flexible: true,
        movable: true,
        data: {
          src: sound.url,
          name: sound.text,
          run_id: runId,
          fileId: newId,
          text: sound.text,
        },
      };
      let newData = [...data];

      if (oldAction.effectId === 'prompt') {
        newData[selectedCoords.row_i].actions[selectedCoords.action_i] = newAction;
      } else if (oldAction.effectId === 'audio') {
        const newRowActions = [...newData[selectedCoords.row_i].actions];
        newRowActions[selectedCoords.action_i] = newAction;
        const newRow = {
          id: newData.length,
          actions: newRowActions,
        };
        for (const action_i_str in newRow.actions) {
          const action_i = parseInt(action_i_str);
          const action = { ...newRow.actions[action_i] };
          if (action.effectId === 'placeholder') {
            action.id = action.id - newRow.actions.length * newRow.id;
            newRow.actions[action_i] = action;
          }
        }
        newData = [
          ...newData.slice(0, selectedCoords.row_i + 1),
          newRow,
          ...newData.slice(selectedCoords.row_i + 1),
        ];
        setSelectedCoords({
          row_i: selectedCoords.row_i + 1,
          action_i: selectedCoords.action_i,
        });
      }

      setData(newData);
      mixpanel.track('Add to Timeline', {
        category: 'Sound',
        text: sound.text,
      });

      audioControl.load({
        id: newAction.id,
        src: newAction.data.src,
        startTime: newAction.start,
      });

      // when adding a sound to the last two rows
      if (selectedCoords.row_i >= data.length - 2) {
        addRow();
      }
    }
  };

  const packageProject = (): ProjectPackage => {
    return {
      data: data,
      isMuted: isMuted,
      isLocked: isLocked,
      volumes: volumes,
    };
  };

  const loadProjectPackage = (projectPackage: ProjectPackage) => {
    const videoAction = projectPackage.data[0].actions[0];
    setVideoInfo(videoAction.id, videoAction.data.src);
    setDataRaw(projectPackage.data);
    setIsMuted(projectPackage.isMuted);
    setIsLocked(projectPackage.isLocked);
    setVolumes(projectPackage.volumes);
    // todo: videoAction.id is not set correctly
  };

  const addNewAudio = (audio: Audio) => {
    console.log('Adding audio to timeline: ' + audio);
    const newId = audio.id;
    let end = audio.duration;
    if (end > videoRef.current.duration) {
      end = videoRef.current.duration;
    }
    const newAction = {
      id: assignActionId(newId),
      effectId: audio.type,
      start: 0,
      end: end,
      flexible: true,
      movable: true,
      data: {
        src: audio.url,
        name: audio.text,
        run_id: runId,
        text: audio.text,
        fileId: newId,
        srcStart: audio.srcStart,
        srcEnd: audio.srcEnd,
      },
    };
    const newData = [...data];
    console.log('newData:', newData);

    // Find the first row without a video, audio, or voice ribbon
    const targetRowIndex = newData.findIndex((row) =>
      row.actions.every(
        (action) =>
          action.effectId !== 'video' &&
          action.effectId !== 'audio' &&
          action.effectId !== 'voice' &&
          action.effectId !== 'music'
      )
    );

    if (targetRowIndex !== -1) {
      // If a suitable row is found, add the voice action to that row
      newData[targetRowIndex].actions.push(newAction);
    } else {
      // If no suitable row is found, create a new row and add the voice action
      const newRow = {
        id: newData.length,
        actions: [newAction],
      };
      newData.push(newRow);
    }

    setData(newData);
    mixpanel.track('Add Voice to Timeline', {
      category: 'Voice',
      text: audio.text,
    });

    audioControl.load({
      id: newAction.id,
      src: newAction.data.src,
      startTime: newAction.start,
      endTime: newAction.end,
    });
  };

  return (
    <VideoContext.Provider
      value={{
        videoRef,
        videoId,
        videoURL,
        setVideoInfo,
        data,
        setData,
        prompt,
        setPrompt,
        selectedCoords,
        setSelectedCoords,
        runId,
        setRunId,
        addNewSound,
        addNewAudio: addNewAudio,
        undo: undo,
        redo: redo,
        setInitialData,
        PLACEHOLDERS_PER_SECOND,
        resetState,
        tStartSoundBox,
        setTStartSoundBox,
        tEndSoundBox,
        setTEndSoundBox,
        projectId,
        setProjectId,
        projectName,
        setProjectName,
        isLocked,
        setIsLocked,
        isMuted,
        setIsMuted,
        volumes,
        setVolumes,
        packageProject,
        loadProjectPackage,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};

export const useVideoContext = () => {
  const context = useContext(VideoContext);
  if (!context) throw new Error('useVideoContext must be used within a VideoProvider');
  return context;
};
