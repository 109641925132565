import { axios } from '@/utils/axios';

export const callStartMusicGeneration = async (prompt: string, projectId: number) => {
  const response = await axios.post('/api/music/start_generation/', {
    prompt: prompt,
    project_id: projectId,
  });
  return response.retrieval_id;
};

export const callMusicResult = async (retrievalId: number) => {
  return await axios.post('/api/music/retrieve/', {
    retrieval_id: retrievalId,
  });
};

export const callMusicSuggestion = async (projectId: number) => {
  await new Promise((resolve) => setTimeout(resolve, 2500)); // Wait for 3 seconds before retrying
  return await axios.post('/api/music/suggest/', {
    project_id: projectId,
  });
};
