import { Helmet } from 'react-helmet-async';

type HeadProps = {
  title?: string;
  description?: string;
};

export const Head = ({ title = '', description = '' }: HeadProps = {}) => {
  return (
    <Helmet title={title ? `${title} | Adorno` : undefined} defaultTitle="Adorno">
      <meta name="description" content={description} />
      <link
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:wght@300"
        rel="stylesheet"
      />
    </Helmet>
  );
};
