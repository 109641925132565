import { axios } from '@/utils/axios';

export const startRun = async (
  video_id: number,
  start: number,
  end: number,
  prompt: string
): Promise<void> => {
  const data = await axios.post(`/api/start_run/`, {
    video_id: video_id,
    start: start,
    end: end,
    prompt: prompt,
  });
  return data.run_id;
};

export const retrieveSounds = async (run_id: number): Promise<void> => {
  return await axios.post(`/api/retrieve_sounds/`, { run_id: run_id });
};
