const storagePrefix = 'bulletproof_react_';

const storage = {
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`) as string);
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
  getEmail: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}email`) as string);
  },
  setEmail: (email: string) => {
    window.localStorage.setItem(`${storagePrefix}email`, JSON.stringify(email));
  },
  clearEmail: () => {
    window.localStorage.removeItem(`${storagePrefix}email`);
  },
  clearStorage: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
    window.localStorage.removeItem(`${storagePrefix}email`);
  },
};

export default storage;
