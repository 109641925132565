export let API_URL = process.env.REACT_APP_API_URL as string;
// ensure API_URL doesn't have a forwardslash at the end
if (API_URL.endsWith('/')) {
  API_URL = API_URL.slice(0, -1);
}

export const JWT_SECRET = '123456' as string;
export const DEFAULT_VIDEO_URL = process.env.REACT_APP_DEFAULT_VIDEO_URL as string;
export const DEFAULT_VIDEO_ID = parseInt(process.env.REACT_APP_DEFAULT_VIDEO_ID as number);
export const DEFAULT_PROJECT_ID = parseInt(process.env.REACT_APP_DEFAULT_PROJECT_ID as number);
