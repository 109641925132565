import { axios } from '@/utils/axios';

export const getUserPackage = async (): Promise<void> => {
  // we leave data empty - user email gets populated automatically
  return await axios.post(`/api/package/`, {});
};

export const saveProjectEndpoint = async (projectId, data, projectName): Promise<void> => {
  const postData = { project_id: projectId, savepoint: data };
  if (projectName) {
    postData.name = projectName;
  }
  return await axios.post(`/api/save_project/`, postData);
};
