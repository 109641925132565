import React, { useEffect, useState } from 'react';

import { useVideoContext } from '@/pages/editor/context';

import FullScreenIcon from './assets/full_screen.png';
import './styles.scss';

export const VideoPlayer: React.FC = () => {
  const { videoURL, videoRef } = useVideoContext();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const updateCurrentTime = () => setCurrentTime(video.currentTime);
      const setVideoDuration = () => setDuration(video.duration || 0);

      video.addEventListener('loadedmetadata', setVideoDuration);
      video.addEventListener('timeupdate', updateCurrentTime);

      // Update isPlaying state when video plays
      const onPlay = () => setIsPlaying(true);
      video.addEventListener('play', onPlay);

      // Update isPlaying state when video pauses
      const onPause = () => setIsPlaying(false);
      video.addEventListener('pause', onPause);

      return () => {
        video.removeEventListener('loadedmetadata', setVideoDuration);
        video.removeEventListener('timeupdate', updateCurrentTime);
        video.removeEventListener('play', onPlay);
        video.removeEventListener('pause', onPause);
      };
    }
  }, [videoRef]);

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    }
  };

  const handleSeek = (event) => {
    const video = videoRef.current;
    if (video) {
      const seekTime = (event.target.value / 10000) * duration;
      video.currentTime = seekTime;
      setCurrentTime(seekTime);
    }
  };

  const toggleFullScreen = () => {
    const video = videoRef.current;
    if (video) {
      if (!document.fullscreenElement) {
        video.requestFullscreen().catch((err) => {
          alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
        video.setAttribute('controlsList', 'nodownload');
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          video.removeAttribute('controlsList');
        }
      }
    }
  };

  const formatTime = (seconds) => {
    const pad = (num, size) => ('000' + num).slice(size * -1);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    // Include hundredths of a second
    const secs = Math.floor(seconds % 60);
    const hundredths = Math.floor((seconds % 1) * 100); // Get the fractional part of seconds

    let result = '';
    if (hours > 0) {
      result += pad(hours, 2) + ':';
    }
    result += pad(minutes, 2) + ':' + pad(secs, 2) + '.' + pad(hundredths, 2);

    return result;
  };

  const isPortraitVideo = (): boolean => {
    const videoElement = videoRef.current;
    if (videoElement && videoElement.videoHeight > 0 && videoElement.videoWidth > 0) {
      return videoElement.videoHeight > videoElement.videoWidth;
    }
    return false;
  };

  return (
    <div className="videoContainer">
      {videoURL ? (
        <>
          <video
            src={videoURL}
            ref={videoRef}
            className="videoPlayer"
            onClick={togglePlayPause}
            controls={false}
          >
            Your browser does not support the video tag.
          </video>
          <div className="videoControls">
            <span className="time-duration">
              {isPortraitVideo()
                ? formatTime(currentTime)
                : `${formatTime(currentTime)}/${formatTime(duration)}`}
            </span>
            <button className="play-pause" onClick={togglePlayPause}>
              {isPlaying ? '❚❚' : '►'}
            </button>
            <button className="full-screen" onClick={toggleFullScreen}>
              <img src={FullScreenIcon} alt="Full Screen" />
            </button>
            <input
              type="range"
              min="0"
              max="10000"
              value={(currentTime / duration) * 10000}
              onChange={handleSeek}
              className="seekBar"
            />
          </div>
        </>
      ) : (
        <p className="text-center">
          No video uploaded - head to&nbsp;
          <a className="text-blue-300 font-bold" href="/upload">
            upload page
          </a>
        </p>
      )}
    </div>
  );
};
