import { useAuth0 } from '@auth0/auth0-react';
import mixpanel from 'mixpanel-browser';
import React, { FC, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Preloader from '@/components/Preloader/preloader';
import { useVideoContext } from '@/pages/editor/context';
import storage from '@/utils/storage';

import './header.scss';
import { getUserPackage, saveProjectEndpoint } from './api';
import logoImg from './logo.png';
import { handleExportAudio, exportMixedAudio, exportVideoWithMixedAudio } from './utils';

interface HeaderProps {
  showProjectControls: boolean;
  showBackToUpload: boolean;
}

export const Header: FC<HeaderProps> = ({ showProjectControls, showBackToUpload = false }) => {
  const { data, resetState, projectId, projectName, setProjectName, packageProject } =
    useVideoContext();
  const { user, logout } = useAuth0();
  const userEmail = user?.email;
  const userInitial = userEmail ? userEmail[0].toUpperCase() : '';
  const [showExportModal, setShowExportModal] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [selectedExportOption, setSelectedExportOption] = useState<string | null>(null);
  const [showNewProjectModal, setShowNewProjectModal] = useState(false);
  const [showSaveProjectModal, setShowSaveProjectModal] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const newProjectModalRef = useRef<HTMLDivElement>(null);
  const saveProjectModalRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [pricingPackage, setPricingPackage] = useState<string | null>('FREE');

  const handleExportOptionClick = async (option: string) => {
    console.log('handleExportOptionClick');
    setIsExporting(true);
    setSelectedExportOption(option);
    try {
      switch (option) {
        case 'Export sounds individually':
          mixpanel.track('Sounds Exported Individually', {
            category: 'Export',
            exportType: 'Individual Sounds',
          });
          await handleExportAudio(data);
          break;
        case 'Export mixed track':
          mixpanel.track('Mixed Audio Track Exported', {
            category: 'Export',
            exportType: 'Mixed Audio',
          });
          await exportMixedAudio(data);
          break;
        case 'Export video with mixed audio':
          mixpanel.track('Video with Mixed Audio Exported', {
            category: 'Export',
            exportType: 'Video/Audio',
          });
          await exportVideoWithMixedAudio(data);
          break;
        default:
          console.error('Unknown export option');
      }
      mixpanel.track('Export Any', {
        category: 'Export',
        exportType: option,
      });
    } finally {
      setIsExporting(false);
      setShowExportModal(false);
      setSelectedExportOption(null);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShowExportModal(false);
        setShowSaveProjectModal(false);
        setShowNewProjectModal(false);
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleNewProject = () => {
    setShowNewProjectModal(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(event.target.value);
  };

  const saveProject = () => {
    saveProjectEndpoint(projectId, packageProject(), projectName);
    setShowSaveProjectModal(false);
    mixpanel.track('Save Project', {
      category: 'Project',
      projectId: projectId,
      projectName: projectName,
    });
  };

  const confirmNewProject = () => {
    resetState();
    navigate('/upload');
    mixpanel.track('Confirm New Project', {
      category: 'New Project',
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        console.log('handleClickOutside and setShowExportModal set to false');
        setShowExportModal(false);
        setSelectedExportOption(null);
      }
      if (
        newProjectModalRef.current &&
        !newProjectModalRef.current.contains(event.target as Node)
      ) {
        setShowNewProjectModal(false);
      }
      if (
        saveProjectModalRef.current &&
        !saveProjectModalRef.current.contains(event.target as Node)
      ) {
        setShowSaveProjectModal(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const refreshUserPackage = () => {
    getUserPackage().then((response) => {
      setPricingPackage(response.package);
      console.log('Setting package to ' + response.package);
      if (response.package === 'FREE') {
        // if user is on the free package, refresh their package every 15 seconds
        // ideally we should start this refresh on specific conditions, but this
        // is more reliable
        setTimeout(() => {
          refreshUserPackage();
        }, 15000); // 15 seconds
      }
    });
  };

  useEffect(() => {
    refreshUserPackage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoutUser = () => {
    // note that the `logout` identifier is already used by Auth0
    storage.clearStorage();
    resetState();
    logout();
    navigate('/upload');
  };

  return (
    <div className={'header ' + (showProjectControls ? ' show-project-controls' : '')}>
      <div className="header-left logo-and-user-info">
        <div className="logo-box">{userInitial}</div>
        <div className="user-info">
          <div className="user-name">{userEmail}</div>
          <div className="membership-status">{pricingPackage} PACKAGE</div>
        </div>
      </div>
      <div className="profile-dropdown">
        <div className="menu-item action-button" onClick={() => navigate('/account')}>
          <h2 className="text-left">
            <span className="material-symbols-outlined">manage_accounts</span>
            Account
          </h2>
        </div>
        <div className="menu-item action-button" onClick={logoutUser}>
          <h2 className="text-left">
            <span className="material-symbols-outlined">logout</span>
            Logout
          </h2>
        </div>
      </div>
      {window.innerWidth > 1200 && (
        <div className="header-center">
          <img src={logoImg} alt="Logo" className="logo-image" />
        </div>
      )}
      {showProjectControls && (
        <div className="header-right">
          <button
            className="action-button"
            id="saveProjectButton"
            onClick={() => setShowSaveProjectModal(true)}
          >
            <h2>
              <span className="material-symbols-outlined">save</span>
              Save
            </h2>
          </button>
          <button className="action-button" id="newProjectButton" onClick={handleNewProject}>
            <h2>
              <span className="material-symbols-outlined">add_circle</span>
              New Project
            </h2>
          </button>
          <button
            className="action-button"
            id="exportButton"
            onClick={() => setShowExportModal(true)}
          >
            <h2>
              <span className="material-symbols-outlined">download</span>
              Export
            </h2>
          </button>
        </div>
      )}
      {showBackToUpload && (
        <div className="header-right">
          <div
            className="cursor-pointer back-button"
            title="Back to upload page"
            onClick={() => navigate('/upload')}
          >
            <span className="material-symbols-outlined inline-block">arrow_back_ios</span>
            Back
          </div>
        </div>
      )}
      {showNewProjectModal && (
        <div className="modal-overlay">
          <div className="modal-content" ref={newProjectModalRef}>
            <p className="new-project-message">
              Are you sure you want to create a new project?
              <br />
              All progress will be lost.
            </p>
            <div className="button-wrapper">
              <button onClick={confirmNewProject}>Yes</button>
              <button onClick={() => setShowNewProjectModal(false)}>No</button>
            </div>
          </div>
        </div>
      )}
      {showSaveProjectModal && (
        <div className="modal-overlay">
          <div className="modal-content" ref={saveProjectModalRef}>
            <p className="new-project-message">
              Project name:
              <input type="text" autoFocus value={projectName} onChange={handleInputChange} />
            </p>
            <div className="button-wrapper">
              <button onClick={saveProject}>Save</button>
              <button onClick={() => setShowSaveProjectModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      {showExportModal && (
        <div className="modal-overlay">
          <div className="modal-content" ref={modalRef}>
            {[
              'Export sounds individually',
              'Export mixed track',
              'Export video with mixed audio',
            ].map((option) => (
              <div
                key={option}
                className={`export-option ${selectedExportOption === option ? 'selected' : ''}`}
                onClick={() => handleExportOptionClick(option)}
              >
                {option}
              </div>
            ))}
            {isExporting && (
              <div className="download-in-progress">
                <div className="download-message">Preparing download...</div>
                <Preloader />
              </div>
            )}
            <div className="button-wrapper">
              <button onClick={() => setShowExportModal(false)}>Close</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
