import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';

// import garbageIcon from './assets/garbage.png';
import pauseIcon from './assets/pause.png';
import playIcon from './assets/play.png';
import plusIcon from './assets/plus.png';

import './container.scss';

interface SoundContainerProps {
  url: string;
  index: number;
  onRemoveSound: (index: number) => void;
  onAddToTimeline: () => void;
  currentPlayingIndex: number | null;
  setCurrentPlayingIndex: () => void;
}

export const SoundContainer: React.FC<SoundContainerProps> = ({
  url,
  index,
  // onRemoveSound,
  onAddToTimeline,
  currentPlayingIndex,
  setCurrentPlayingIndex,
}) => {
  const waveSurferRef = useRef<WaveSurfer | null>(null);
  const waveformContainerRef = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (url && waveformContainerRef.current) {
      const waveSurfer = WaveSurfer.create({
        container: waveformContainerRef.current,
        cursorWidth: 1,
        height: 30,
        progressColor: '#cb7e97',
        waveColor: '#b0b0b0',
        responsive: true,
        normalize: true,
        barWidth: 1,
        barRadius: 2,
      });
      waveSurfer.load(url);
      waveSurfer.on('play', () => setIsPlaying(true));
      waveSurfer.on('pause', () => setIsPlaying(false));
      waveSurferRef.current = waveSurfer;
    }

    return () => {
      waveSurferRef.current?.destroy();
      //   waveSurferRef.current = null;
    };
  }, [url]);

  const togglePlayPause = () => {
    if (currentPlayingIndex !== null && currentPlayingIndex !== index) {
      setCurrentPlayingIndex(index);
    } else {
      waveSurferRef.current?.playPause();
      setCurrentPlayingIndex(waveSurferRef.current?.isPlaying() ? index : null);
    }
  };

  useEffect(() => {
    if (currentPlayingIndex !== null && currentPlayingIndex !== index) {
      waveSurferRef.current?.pause();
    } else if (currentPlayingIndex === index) {
      waveSurferRef.current?.play();
    }
  }, [currentPlayingIndex, index]);

  return (
    <div className="soundContainer">
      <div className="playButton" onClick={togglePlayPause}>
        <img src={isPlaying ? pauseIcon : playIcon} alt="Play/Pause" />
      </div>
      <div className="waveformContainer" ref={waveformContainerRef}></div>
      {/* <button className="garbageButton" onClick={() => onRemoveSound(index)}>
        <img src={garbageIcon} alt="Delete" />
      </button> */}
      <div className="plusButton" onClick={onAddToTimeline}>
        <img src={plusIcon} alt="Add" className="addSoundPlus" />
      </div>
    </div>
  );
};

export default SoundContainer;
