import { useAuth0 } from '@auth0/auth0-react';
import mixpanel from 'mixpanel-browser';

import Preloader from '@/components/Preloader/preloader';
import { getConfig } from '@/config/config';
import { LoginPage } from '@/pages/login';
import { AppProvider } from '@/providers/app';
import { AppRoutes } from '@/routes';

import { VideoProvider } from './pages/editor/context';
import './index.css';

const { mixPanelToken } = getConfig();
mixpanel.init(mixPanelToken, {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
});

function App() {
  const { isAuthenticated, isLoading, error, user } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return (
      <div className="centered-container">
        <Preloader />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <LoginPage />;
  }

  // Set the user profile properties in Mixpanel
  mixpanel.identify(user.sub);
  mixpanel.people.set({
    $email: user.email,
    $name: user.name,
  });

  return (
    <AppProvider>
      <VideoProvider>
        <AppRoutes />
      </VideoProvider>
    </AppProvider>
  );
}

export default App;
