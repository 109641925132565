import configJson from './auth_config.json';

export function getConfig() {
  const audience =
    configJson.audience && configJson.audience !== 'https://app.adorno.ai'
      ? configJson.audience
      : null;

  return {
    domain: configJson.domain,
    clientId: configJson.clientId,
    ...(audience ? { audience } : null),
    baseBackendUrl: configJson.baseBackendUrl,
    apiConfig: configJson.apiConfig,
    googleAnalyticsCode: configJson.googleAnalyticsCode,
    mixPanelToken: configJson.mixPanelToken,
  };
}
