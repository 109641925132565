import { useAuth0 } from '@auth0/auth0-react';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';

import ProgressBar from '@/components/Progressbar';

import { useVideoContext } from '../context';

import { retrieveSounds, startRun } from './api';
import animationVideoMP4 from './assets/animation.mp4';
import animationVideoWebM from './assets/animation.webm';
import fallbackImage from './assets/fallback.png';
import { SoundContainer } from './container';

import './styles.scss';

const initialSounds = {
  complete: true,
  run_id: undefined,
  prompt: {
    text: 'Prompt data coming back from API',
    sounds: [],
  },
  suggestions: [1, 2, 3, 4].map((i) => {
    return { description_id: i, text: 'Suggestion' + i, sounds: [] };
  }),
};

export const SoundBox: React.FC = () => {
  const [isDefaultDisplay, setIsDefaultDisplay] = useState(true);
  const [activeSelection, setActiveSelection] = useState('PROMPT');

  // todo: this needs to be refactored out
  const {
    prompt,
    addNewSound,
    selectedCoords,
    data,
    videoId,
    runId,
    setRunId,
    tStartSoundBox,
    tEndSoundBox,
  } = useVideoContext();
  const [files, setFiles] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [apiData, setApiData] = useState(initialSounds);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState<number | null>(null);

  const [loading, setLoading] = useState(false);
  const estimatedTime = 15;

  const [loadingTextBase, setLoadingTextBase] = useState('Video analyzed');
  const [ellipsis, setEllipsis] = useState('.');
  const [showOutOfCredits, setShowOutOfCredits] = useState(false);
  const loadingText = `${loadingTextBase} ${ellipsis}`;
  const { user } = useAuth0();
  const userEmail = user?.email;

  // function to remove a sound from the list
  const removeSound = (sound_i: number) => {
    // Clone the current state deeply to ensure immutability
    const newApiData = JSON.parse(JSON.stringify(apiData));

    const suggestionObject = newApiData.suggestions.find(
      (suggestion) => suggestion.name === activeSelection
    );

    // Check if suggestionObject is defined before attempting to modify it
    if (suggestionObject && suggestionObject.files) {
      // Remove the sound from the cloned object
      suggestionObject.files.splice(sound_i, 1);

      // Update the state with the modified clone
      setApiData(newApiData);

      // If the active selection matches the modified suggestion, update the files displayed in the UI
      if (activeSelection === suggestionObject.name) {
        setFiles([...suggestionObject.files]);
      }
    } else {
      // Handle the case where suggestionObject is undefined
      console.error('Selected suggestion not found');
    }

    mixpanel.track('Remove Sound', {
      category: 'Sound',
      type: 'Remove Sound',
      sound: sound_i,
    });
  };

  // function to handle the input change
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = e.target;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  };

  // function to handle the prompt sound generation
  const generatePromptSounds = () => {
    if (activeSelection === 'PROMPT') {
      setActiveSelection('');
    } else {
      setActiveSelection('PROMPT');
      setFiles(apiData.prompt.files);
    }
    mixpanel.track('Generate Prompt Sounds', {
      category: 'Sound',
      type: 'Generate Prompt Sounds',
      prompt: prompt,
    });
  };

  // function to select an effect
  const selectEffect = (effect: string) => {
    if (activeSelection === effect) {
      setActiveSelection('');
    } else {
      setActiveSelection(effect);
      const suggestion = apiData.suggestions.find((element) => element.text === effect);
      setFiles(suggestion.files);
    }
  };

  // load the results from the API for suggestions
  const loadApiResult = (newData) => {
    setApiData(newData);
    setSuggestions(newData.suggestions.map((suggestion) => suggestion.text));
    setFiles(newData.prompt.files);
  };

  // Async function that recursively queries the API
  const retrieveSoundTillComplete = async (run_id) => {
    // Make a request to the API
    const newSoundData = await retrieveSounds(run_id);
    console.log('newSoundData', newSoundData);
    // Check if the response data meets the condition
    setApiData(newSoundData);

    if (!newSoundData.complete) {
      await new Promise((resolve) => setTimeout(resolve, 800)); // Wait for 0.8 second before retrying
      return retrieveSoundTillComplete(run_id); // Recursive call
    }

    setLoading(false);
    return newSoundData;
  };

  useEffect(() => {
    if (!prompt && !tEndSoundBox) {
      return;
    }
    setLoading(true);
    const action = data[selectedCoords.row_i].actions[selectedCoords.action_i];
    startRun(videoId, action.start, action.end, prompt)
      .then((run_id) => {
        setRunId(run_id);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.message.includes('credit')
        ) {
          console.error('A credit error occurred:', error);
          setLoading(false);
          setShowOutOfCredits(true);
        } else {
          // throw other errors like normal
          console.log('This should never happen');
          throw error;
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prompt, tStartSoundBox, tEndSoundBox]);

  useEffect(() => {
    if (!runId || apiData.run_id === runId) {
      // skip if already loaded
      return;
    }
    console.log('Loading run...' + runId);

    setLoading(true);
    retrieveSoundTillComplete(runId).then((newData) => {
      loadApiResult(newData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runId]);

  useEffect(() => {
    // Texts to be cycled through
    const texts = ['Video analyzed', 'Scene Decomposition', 'Depth Perception'];
    let textIndex = 0;
    let ellipsisIndex = 0;

    const updateEllipsis = () => {
      ellipsisIndex = (ellipsisIndex + 1) % 4; // Cycle through 0 to 3
      setEllipsis('.'.repeat(ellipsisIndex));
    };

    // Update ellipsis every 500 milliseconds for a smoother animation
    const ellipsisInterval = setInterval(updateEllipsis, 500);

    // Change text every 3 seconds
    const textChangeInterval = setInterval(() => {
      textIndex = (textIndex + 1) % texts.length;
      setLoadingTextBase(texts[textIndex]);
    }, 5000);

    // Cleanup on component unmount
    return () => {
      clearInterval(ellipsisInterval);
      clearInterval(textChangeInterval);
    };
  }, []);

  useEffect(() => {
    if (loading) {
      setIsDefaultDisplay(false);
    }
  }, [loading]);

  const openSubscriptionPage = () => {
    // todo: prefill email
    const paymentUrl = 'https://buy.stripe.com/dR64i5bzQgY6eNafYZ?prefilled_email=' + userEmail;
    window.open(paymentUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <div className="soundBox">
        {isDefaultDisplay && !loading && (
          <div className="defaultMessage">
            <video width="70%" height="auto" autoPlay loop muted preload="auto" playsinline>
              <source src={animationVideoMP4} type="video/mp4" />
              <source src={animationVideoWebM} type="video/webm" />
              <img src={fallbackImage} alt="Fallback Image" />
              Your browser does not support the video tag, try another one!
            </video>
            <p className="messageSentence">
              Click & Drag your mouse over the timeline to start! 🎬
            </p>
          </div>
        )}
        {loading && (
          <div className="progressBarOverlay">
            <div className="loadingTextWrapper">
              <h2 className="loadingText">{loadingText}</h2>
              <div className="progressBar">
                <div className="progressbar-container">
                  <ProgressBar expectedDuration={estimatedTime} isCompleted={!loading} />
                </div>
              </div>
            </div>
          </div>
        )}
        {!loading && !isDefaultDisplay && (
          <>
            <div className="promptSection">
              {prompt && prompt.trim() !== '' && (
                <>
                  <h3>Prompt</h3>
                  <div
                    className={`promptInputContainer ${
                      activeSelection === 'PROMPT' ? 'activePrompt' : ''
                    }`}
                  >
                    <textarea
                      className="promptInput"
                      value={prompt}
                      onChange={handleInputChange}
                      onClick={generatePromptSounds}
                      placeholder="Write your prompt here..."
                    ></textarea>
                  </div>
                </>
              )}

              <div className="adornosSuggestion">
                <h3>Adorno's Suggestions</h3>
                <div className="effectsContainer">
                  {suggestions.map((text) => (
                    <div
                      key={text}
                      className={`effect ${activeSelection === text ? 'active' : ''}`}
                      onClick={() => selectEffect(text)}
                    >
                      {text}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="generatedSoundSection">
              <h3>Generated Sound</h3>
              <div className="soundHeader">
                {files.map(({ id, url }) => (
                  <SoundContainer
                    key={id}
                    url={url}
                    index={id}
                    onRemoveSound={removeSound}
                    onAddToTimeline={() => addNewSound({ url: url, text: activeSelection, id: id })}
                    currentPlayingIndex={currentPlayingIndex}
                    setCurrentPlayingIndex={setCurrentPlayingIndex}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      {showOutOfCredits && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>You've used 120 prompts this month - the maximum available on the free tier.</p>
            <p>
              <b>Subscribe to the Pro plan for unlimited audio generation.</b>
            </p>
            <div className="button-wrapper">
              <button onClick={() => setShowOutOfCredits(false)}>Back</button>
              <button onClick={openSubscriptionPage}>Start free trail</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SoundBox;
