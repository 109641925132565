import React, { useEffect, useState } from 'react';
import './progress_bar.scss';

interface ProgressBarProps {
  expectedDuration: number;
  isCompleted: boolean; // This prop will be used to trigger the rapid completion to 100
}

const ProgressBar: React.FC<ProgressBarProps> = ({ expectedDuration, isCompleted }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (expectedDuration <= 0) {
      setProgress(0);
      return;
    }

    const totalDurationMs = expectedDuration * 1000; // Convert seconds to milliseconds
    // Dynamically adjust increment time to be a portion of the total duration, resulting in a smooth progress
    const incrementTimeMs = totalDurationMs / 100;

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        // Adjust progression logic based on current progress
        let newIncrement = 0.6;

        if (prevProgress > 60) {
          newIncrement = 0.5;
        } else if (prevProgress >= 80) {
          // becomes slower and slower
          const slow_factor = prevProgress - 79;
          newIncrement = 0.5 / slow_factor;
        }

        const nextProgress = prevProgress + newIncrement;
        // Ensure the progress does not exceed 99%
        return nextProgress > 99 ? 99 : nextProgress;
      });
    }, incrementTimeMs);

    return () => clearInterval(interval);
  }, [expectedDuration, isCompleted]);

  return (
    <div className="progress" id="progressBar">
      <div
        className="progress-number"
        aria-valuenow={Math.round(progress)}
        style={{ left: `calc(${progress}% - ${progress === 99 ? '20px' : '10px'})` }}
      ></div>
      <div className="rounded">
        <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
};

export default ProgressBar;
