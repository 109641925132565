import { Timeline } from '@xzdarcy/react-timeline-editor';
import { Slider } from 'antd';
import mixpanel from 'mixpanel-browser';
import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import audioControl from '@/pages/editor/timeline/audioControl';
import { loadProjectEndpoint } from '@/pages/videoUpload/api';

import { useVideoContext, ActionCoords } from '../context';

import './styles.scss';
import LockIcon from './assets/lock.png';
import MuteIcon from './assets/mute.png';
import UnlockIcon from './assets/unlock.png';
import VolumeIcon from './assets/volume.png';
import ZoomIcon from './assets/zoom.png';
import { ribbonTypeMap, scale, scaleWidth, startLeft, RenderRibbon } from './ribbons';

export const VideoEditor: FC = () => {
  const timelineState = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [videoDuration, setVideoDuration] = useState<number>(0);
  const {
    data,
    setData,
    videoRef,
    selectedCoords,
    setSelectedCoords,
    setRunId,
    undo,
    redo,
    setInitialData,
    PLACEHOLDERS_PER_SECOND,
    isLocked,
    setIsLocked,
    isMuted,
    setIsMuted,
    volumes,
    setVolumes,
    setProjectId,
    loadProjectPackage,
  } = useVideoContext();
  const navigate = useNavigate();

  const minScale = 0.25;
  const maxScale = 3;
  const defaultInitialScale = (minScale + maxScale) / 2;
  const [timelineScale, setTimelineScale] = useState(defaultInitialScale);
  const [clipboardCoords, setClipboardCoords] = useState<ActionCoords>({
    action_i: null,
    row_i: null,
  });
  const [searchParams] = useSearchParams();
  const [actionCentreY, setActionCentreY] = useState(0);
  const [isDraggingAction, setIsDraggingAction] = useState(false);
  const [selectedAction, setSelectedAction] = useState<Record<string, any> | null>(null);

  let urlProjectId: string | null = searchParams.get('projectId');
  if (urlProjectId) {
    urlProjectId = parseInt(urlProjectId);
    setProjectId(urlProjectId);
    loadProjectEndpoint(urlProjectId).then((response) => {
      loadProjectPackage(response.data);
    });
  }

  useEffect(() => {
    if (videoDuration > 0) {
      const videoRibbonElement = document.querySelector('.ribbon-text.video-ribbon');
      if (videoRibbonElement) {
        const videoRibbonWidth = videoRibbonElement.clientWidth;
        const timelineElement = document.querySelector('.timeline-editor');
        if (timelineElement) {
          const timelineWidth = timelineElement.clientWidth;
          const newScale = Math.min(Math.max(timelineWidth / videoRibbonWidth, minScale), maxScale);
          setTimelineScale(newScale);
        }
      }
    }
  }, [videoDuration]);

  useEffect(() => {
    const handleResize = () => {
      const videoRibbonElement = document.querySelector('.ribbon-text.video-ribbon');
      if (videoRibbonElement) {
        const videoRibbonWidth = videoRibbonElement.clientWidth;
        const timelineElement = document.querySelector('.timeline-editor');
        if (timelineElement) {
          const timelineWidth = timelineElement.clientWidth;
          const newScale = Math.min(Math.max(timelineWidth / videoRibbonWidth, minScale), maxScale);
          setTimelineScale(newScale);
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [videoDuration]);

  const setInitialVideoDuration = () => {
    setVideoDuration(videoRef.current.duration);
  };

  function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  const toggleLock = (id) => {
    setIsLocked((prev) => {
      const newIsLocked = { ...prev, [id]: !prev[id] };
      const newData = data.map((row) => {
        if (row.id === id) {
          const updatedActions = row.actions.map((action) => ({
            ...action,
            flexible: !newIsLocked[id],
            movable: !newIsLocked[id],
          }));
          return { ...row, actions: updatedActions };
        }
        return row;
      });
      setData(newData);
      return newIsLocked;
    });
  };

  const toggleMute = useCallback(
    (rowId) => {
      // Toggle the mute state for all audio actions in the specified row
      const currentlyMuted = isMuted[rowId] || false;

      // Find all audio actions for the given row and mute/unmute them
      data.forEach((row) => {
        if (row.id === rowId) {
          row.actions.forEach((action) => {
            if (
              action.effectId === 'audio' ||
              action.effectId === 'voice' ||
              action.effectId === 'music'
            ) {
              if (currentlyMuted) {
                audioControl.unmute(action.id);
              } else {
                audioControl.mute(action.id);
              }
            } else if (action.effectId === 'video') {
              if (currentlyMuted) {
                videoRef.current.muted = false;
              } else {
                videoRef.current.muted = true;
              }
            }
          });
        }
      });

      // Update the isMuted state for the given row
      setIsMuted((prevState) => ({
        ...prevState,
        [rowId]: !currentlyMuted,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMuted, setIsMuted, data]
  );

  const handleVolumeChange = (rowId, value) => {
    setVolumes((prevState) => ({
      ...prevState,
      [rowId]: value,
    }));

    data.forEach((row) => {
      if (row.id === rowId) {
        row.actions.forEach((action) => {
          if (
            action.effectId === 'audio' ||
            action.effectId === 'voice' ||
            action.effectId === 'music'
          ) {
            audioControl.setVolume(action.id, value / 100);
          } else if (action.effectId === 'video' && videoRef.current) {
            videoRef.current.volume = value / 100;
          }
        });
      }
    });
  };

  // Update the Timeline's scale dynamically based on user input
  const handleScaleChange = (event) => {
    const newScale = parseFloat(event.target.value);
    setTimelineScale(newScale);
  };

  const setVideoTime = ({ time }) => {
    if (!videoRef.current) {
      return;
    }
    if (videoRef.current.currentTime != time) {
      console.log('video time differs, setting time (should only run 1x)');
      videoRef.current.currentTime = time;
    }
  };

  const setTimelineTime = (event) => {
    if (timelineState.current.currentTime != event.target.currentTime) {
      console.log('timeline time differs, setting time (should only run 1x)');
      timelineState.current.setTime(event.target.currentTime);
    }
  };

  const pauseTimeline = () => {
    timelineState.current.pause();
    for (const row of data) {
      for (const action of row.actions) {
        if (action.effectId == 'audio') {
          audioControl.stop(action.id);
        }
      }
    }
    setIsPlaying(false);
  };

  const playTimeline = () => {
    timelineState.current.play();
    setIsPlaying(true);
  };

  useEffect(() => {
    if (!timelineState.current) return;

    if (!videoRef.current) {
      if (!urlProjectId) {
        navigate('/upload');
      }
      return;
    }

    timelineState.current.listener.on('afterSetTime', setVideoTime);

    videoRef.current.addEventListener('play', playTimeline);
    videoRef.current.addEventListener('pause', pauseTimeline);
    videoRef.current.addEventListener('seeked', setTimelineTime);
    videoRef.current.addEventListener('loadedmetadata', setInitialData);
    // set video duration locally - not needed in video context file
    videoRef.current.addEventListener('loadedmetadata', setInitialVideoDuration);

    // Improved keydown event listener for space bar press
    const handleKeyPress = (event: KeyboardEvent) => {
      // don't do anything if focused on a textbox
      if (isEventOnText(event)) {
        return;
      }

      // Check if the pressed key is the space bar
      if (event.key === ' ' && videoRef.current) {
        event.preventDefault(); // Prevent default action (page scroll)
        event.stopPropagation(); // Prevent event from propagating to other elements

        // Toggle play/pause based on the video's current state
        if (videoRef.current.paused) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      }

      // ctrl+y or ctrl+shift+z
      if (
        ((event.ctrlKey || event.metaKey) && event.key === 'y') ||
        ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'z')
      ) {
        event.preventDefault();
        redo();
      } else if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        // ctrl+z
        event.preventDefault();
        undo();
      }
    };

    // Add and remove the keydown event listener
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef]);

  const isEventOnText = (event) => {
    return (
      event.target.type === 'input' ||
      event.target.type === 'text' ||
      event.target.type === 'textarea'
    );
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        if (selectedCoords.action_i) {
          console.log('ESC pressed, removing prompt.'); // Call the onRemove callback when Escape key is pressed
          deletePreviousPrompt();
        } else {
          console.log('ESC pressed, but no active prompt.'); // Call the onRemove callback when Escape key is pressed
        }
      }

      if (isEventOnText(event)) {
        return;
      }

      // when audio selected and pressing DEL / BACKSPACE
      if (
        selectedAction &&
        (selectedAction.effectId === 'audio' ||
          selectedAction.effectId === 'voice' ||
          selectedAction.effectId === 'music') &&
        (event.key === 'Delete' || event.key === 'Backspace')
      ) {
        event.preventDefault();
        if (selectedCoords.action_i !== null) {
          console.log('DEL pressed, removing action.');
          handleDeleteRibbon();
        } else {
          console.log('DEL pressed, but no active action.');
        }
      }

      if ((event.ctrlKey || event.metaKey) && event.key === 'c' && selectedCoords.action_i) {
        console.log('Copying');
        setClipboardCoords(selectedCoords);
      }
    };

    // Add and remove the keydown event listener
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoords, selectedAction]);

  useEffect(() => {
    function getRandomInt(min: number, max: number): number {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const handleKeyPress = (event: KeyboardEvent) => {
      if (isEventOnText(event)) {
        return;
      }

      if ((event.ctrlKey || event.metaKey) && event.key === 'v' && clipboardCoords.row_i) {
        console.log('Pasting');
        let action = data[clipboardCoords.row_i].actions[clipboardCoords.action_i];
        action = deepCopy(action);
        console.log(action.end);
        const slack = 0.01; // avoid actions being too clumped
        const duration = action.end - action.start + slack;
        action.start += duration;
        action.end += duration;
        action.id = Math.floor(action.id / 10000) * 10000 + getRandomInt(0, 10000);

        console.log('action');
        console.log(action);
        data[clipboardCoords.row_i].actions.splice(clipboardCoords.action_i + 1, 0, action);
        setData([...data]);
        setClipboardCoords({
          row_i: clipboardCoords.row_i,
          action_i: clipboardCoords.action_i + 1,
        });

        audioControl.load({
          id: action.id,
          src: action.data.src,
          startTime: action.start,
          endTime: action.end,
        });

        event.preventDefault();
        event.stopPropagation();
      }
    };

    // Add and remove the keydown event listener
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [clipboardCoords, data, setData]);

  const handleActionClick = (row_id: number, action_id: number, resize: boolean) => {
    for (const row_i_str in data) {
      const row_i = parseInt(row_i_str);
      if (data[row_i].id === row_id) {
        for (const action_i_str in data[row_i].actions) {
          const action_i = parseInt(action_i_str);
          if (data[row_i].actions[action_i].id === action_id) {
            console.log('Action found, handling click on action');
            const action = data[row_i].actions[action_i];
            setSelectedAction(action);
            if (selectedCoords.row_i != row_i || selectedCoords.action_i != action_i) {
              if (
                // when there is a previous selection and it's different coords to the new selection
                selectedCoords.action_i !== null
              ) {
                deletePreviousPrompt();
              }

              if (action.effectId == 'audio') {
                console.log('Loading run id ' + action.data.run_id);
                setRunId(action.data.run_id);
              }
            }

            // creating a prompt box only works when dragging - not when clicking
            if (resize && action.effectId === 'placeholder') {
              action.effectId = 'prompt';
            }

            setSelectedCoords({ row_i: row_i, action_i: action_i });
            setData(data);

            mixpanel.track('Action Click', {
              category: 'Timeline',
              'Row ID': row_id,
              'Action ID': action_id,
              resize: resize ? 1 : 0,
            });

            return; // return early after action found
          }
        }
      }
    }
    console.warning('Action not found - this should never happen.');
  };

  const actionResizeStarted = (params) => {
    handleActionClick(params.row.id, params.action.id, true);
  };

  const actionClicked = (e, param) => {
    handleActionClick(param.row.id, param.action.id, false);
  };

  const deletePreviousPrompt = () => {
    const action = data[selectedCoords.row_i].actions[selectedCoords.action_i];

    if (!action || action.effectId != 'prompt') {
      setSelectedCoords({ row_i: null, action_i: null });
      return;
    }

    if (selectedCoords.action_i > 0) {
      const previousAction = data[selectedCoords.row_i].actions[selectedCoords.action_i - 1];
      action.start = previousAction.end;
    } else {
      action.start = 0;
    }
    action.end = action.start + 1 / PLACEHOLDERS_PER_SECOND;
    action.effectId = 'placeholder';
    setData(data);
    setSelectedCoords({ row_i: null, action_i: null });
    mixpanel.track('Delete Previous Prompt', {
      category: 'Timeline',
    });
  };

  const handleDeleteRibbon = () => {
    const rowIndex = selectedCoords.row_i;
    const actionIndex = selectedCoords.action_i;

    if (rowIndex === null || actionIndex === null) {
      return;
    }

    const action = data[rowIndex].actions[actionIndex];

    if (!action) {
      setSelectedCoords({ row_i: null, action_i: null });
      return;
    }

    if (action.effectId === 'video') {
      // don't do anything if clicking on the video
      return;
    }

    if (action.effectId === 'audio' || action.effectId === 'voice' || action.effectId === 'music') {
      // Stop and unload the audio when deleting an audio or voice ribbon
      audioControl.stop(action.id);
      audioControl.unload(action.id);
    }

    // Remove the deleted action from the data array
    data[rowIndex].actions.splice(actionIndex, 1);

    // Update the start and end times of the remaining actions
    for (let i = actionIndex; i < data[rowIndex].actions.length; i++) {
      const currentAction = data[rowIndex].actions[i];
      if (i > 0) {
        const previousAction = data[rowIndex].actions[i - 1];
        currentAction.start = previousAction.end;
      } else {
        currentAction.start = 0;
      }
      currentAction.end = currentAction.start + (currentAction.end - currentAction.start);
    }

    // Clean up any references or data associated with the deleted action
    if (action.effectId === 'audio' || action.effectId === 'voice' || action.effectId === 'music') {
      // Remove the audio from the audioMap
      delete audioControl.audioMap[action.id];
    }

    setData([...data]); // Create a new array reference to trigger re-render
    setSelectedCoords({ row_i: null, action_i: null });
    mixpanel.track('Delete Ribbon', {
      category: 'Timeline',
    });
  };

  useEffect(() => {
    // Track the total duration of audio generated
    const totalAudioDuration = data.reduce((total, row) => {
      const audioDuration = row.actions.reduce((rowTotal, action) => {
        if (action.effectId === 'audio') {
          return rowTotal + (action.end - action.start);
        }
        return rowTotal;
      }, 0);
      return total + audioDuration;
    }, 0);

    mixpanel.track('Audio Generated', {
      category: 'Timeline',
      totalDuration: totalAudioDuration,
    });
  }, [data]);

  useEffect(() => {
    // Track the number of audio ribbons
    const audioRibbonCount = data.reduce((total, row) => {
      const rowAudioCount = row.actions.filter((action) => action.effectId === 'audio').length;
      return total + rowAudioCount;
    }, 0);

    mixpanel.track('Audio Ribbon Count', {
      category: 'Timeline',
      count: audioRibbonCount,
    });
  }, [data]);

  useEffect(() => {
    const updateRibbonTrack = (ev) => {
      const diffY = ev.clientY - actionCentreY;
      const divider = 30;
      const newRelY = Math.sign(diffY) * Math.floor(Math.abs(diffY / divider) + 0.4);
      if (selectedAction !== null && selectedAction?.relY !== newRelY) {
        console.log('Ribbon moved to another track, setting action relY to: ' + newRelY);
        selectedAction.relY = newRelY;
      }
    };

    if (isDraggingAction && selectedAction !== null) {
      window.addEventListener('mousemove', updateRibbonTrack);
    }

    return () => {
      window.removeEventListener('mousemove', updateRibbonTrack);
    };
  }, [isDraggingAction, selectedAction, actionCentreY]);

  const startDrag = ({ action }) => {
    // get centerpoint of action being dragged and store in state
    const htmlQuery = '[data-action-id="' + action.id + '"]';
    const elements = document.querySelectorAll(htmlQuery);
    const element = elements[0];
    const { top, bottom } = element.getBoundingClientRect();
    setActionCentreY((top + bottom) / 2);
    setSelectedAction(action);
    setIsDraggingAction(true);
  };

  const endDrag = ({ action, row }) => {
    setIsDraggingAction(false);

    if (action.relY === 0) {
      // no need to modify action row when not dragged into antoher track
      return;
    }
    setData((currentData) => {
      for (const row_i_str in currentData) {
        const row_i = parseInt(row_i_str);
        if (currentData[row_i].id === row.id) {
          for (const action_i_str in currentData[row_i].actions) {
            const action_i = parseInt(action_i_str);
            if (currentData[row_i].actions[action_i].id === action.id) {
              console.log('Action found after drag');
              currentData[row_i].actions.splice(action_i, 1);
              // todo: check if there is still space...
              let new_row_i = row_i + action.relY;
              new_row_i = Math.max(new_row_i, 1); // min track = 1, track 0 is for video audio
              new_row_i = Math.min(new_row_i, currentData.length - 1); // cannot drag past max track
              currentData[new_row_i].actions.push(action);
            }
          }
        }
      }
      action.relY = 0;

      // find current action by seeking with action.id
      // remove action from the row
      // add action to another row
      // happiness
      return [...currentData];
    });
  };

  return (
    <div className="timeline-editor-engine">
      <div
        onScroll={(e) => {
          const target = e.target as HTMLDivElement;
          timelineState.current.setScrollTop(target.scrollTop);
        }}
        className={'timeline-list'}
      >
        <div className="zoom-icon">
          <img src={ZoomIcon} alt="Zoom Icon" />
        </div>
        <div className="seekBar">
          <div className="scale-adjuster">
            <label htmlFor="scaleRange"></label>
            <input
              id="scaleRange"
              type="range"
              min={minScale.toString()}
              max={maxScale.toString()}
              step="0.1"
              value={timelineScale}
              onChange={handleScaleChange}
            />
          </div>
        </div>

        {data.map((item) => {
          return (
            <div className="timeline-list-item" key={item.id}>
              <div className="icons">
                <img
                  src={isLocked[item.id] ? LockIcon : UnlockIcon}
                  alt={isLocked[item.id] ? 'Lock Icon' : 'Unlock Icon'}
                  onClick={() => toggleLock(item.id)}
                  style={{ cursor: 'pointer' }}
                />
                <img
                  src={isMuted[item.id] ? MuteIcon : VolumeIcon}
                  alt={isMuted[item.id] ? 'Volume Icon' : 'Mute Icon'}
                  onClick={() => toggleMute(item.id)}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <div className="volume-slider">
                <Slider
                  value={volumes[item.id] || 100}
                  onChange={(value) => handleVolumeChange(item.id, value)}
                />
              </div>
            </div>
          );
        })}
      </div>

      <Timeline
        scale={scale}
        scaleWidth={scaleWidth * timelineScale}
        startLeft={startLeft}
        autoScroll={true}
        ref={timelineState}
        editorData={data}
        effects={ribbonTypeMap}
        // could we optimise this function?
        onChange={setData}
        onClickAction={actionClicked}
        onActionResizeStart={actionResizeStarted}
        isPlaying={isPlaying}
        getActionRender={(action, row) => {
          return <RenderRibbon action={action} row={row} selectedId={selectedAction?.id} />;
        }}
        onActionMoveStart={startDrag}
        onActionMoveEnd={endDrag}
        // dragLine={true}  // this causes gittery movements
      />
    </div>
  );
};

export default VideoEditor;
