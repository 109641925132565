import { useAuth0 } from '@auth0/auth0-react';
import mixpanel from 'mixpanel-browser';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; //could we not use react-router-dom here?

import { Head } from '@/components/Head';
import { axios } from '@/utils/axios';
import storage from '@/utils/storage';

import { useVideoContext } from '../editor/context';
import { Header } from '../editor/header';

import {
  getUploadParams,
  setUploadFinished,
  uploadToS3,
  loadProjectEndpoint,
  callDeleteProject,
} from './api';
import ProgressBar from './progress_bar';

import './upload.scss';

const HYPOTHETICAL_UPLOAD_SPEED_MBPS = 5; // Hypothetical upload speed in Megabits per second

export const UploadPage = () => {
  const navigate = useNavigate();
  const [dragging, setDragging] = useState(false);
  const [uploadDuration, setUploadDuration] = useState(0);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);
  const { setVideoInfo, setProjectId, setProjectName, loadProjectPackage } = useVideoContext();
  const [showDurationWarning, setShowDurationWarning] = useState(false);
  const { user } = useAuth0();
  const [showMobileWarning, setShowMobileWarning] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState<File | null>(null);
  const [projects, setProjects] = useState<array | null>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState<number | null>(null);

  useEffect(() => {
    const handleClose = (e) => {
      if (e.key === 'Escape') {
        setShowDurationWarning(false);
      }
    };

    document.addEventListener('keydown', handleClose);
    return () => document.removeEventListener('keydown', handleClose);
  }, []);

  useEffect(() => {
    const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const isSmallScreen = window.innerWidth <= 720;
    if (!isMobileDevice && !isSmallScreen) {
      setShowMobileWarning(false);
    }
  }, []);

  const loadProjects = () => {
    axios.post(`/api/list_projects/`, {}).then((response) => {
      if (response.projects.length) {
        setProjects(response.projects);
      }
    });
  };

  useEffect(() => {
    loadProjects();
  }, []);

  useEffect(() => {
    if (user && user.email) {
      console.log('Setting user email to' + storage.getEmail());
      storage.setEmail(user.email);
    }
  }, [user]);

  const resetState = useCallback(() => {
    // Reset any state related to the upload or video processing here
    setIsUploading(false);
    setUploadDuration(0);
    setDragging(false);
  }, []);

  // fixme: react thinks this might cause an infinite loop
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculateUploadTime = (fileSizeInMB: number) => {
    const uploadSpeedMBps = HYPOTHETICAL_UPLOAD_SPEED_MBPS / 8; // Convert Mbps to MBps
    return fileSizeInMB / uploadSpeedMBps; // Returns time in seconds
  };

  const handleExampleVideoClick = (videoSrc: string) => {
    fetch(videoSrc)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], 'example_video.mp4', { type: 'video/mp4' });
        setSelectedVideo(file);

        // Remove the 'selected' class from all thumbnails
        const thumbnails = document.querySelectorAll('.example-video-thumbnails video');
        thumbnails.forEach((thumbnail) => thumbnail.classList.remove('selected'));

        // Add the 'selected' class to the clicked thumbnail
        const clickedThumbnail = document.querySelector(
          `.example-video-thumbnails video[src="${videoSrc}"]`
        );
        if (clickedThumbnail) {
          clickedThumbnail.classList.add('selected');
        }
      });
  };

  const handleContinueClick = () => {
    if (selectedVideo) {
      handleVideoUpload(selectedVideo);
    }
  };

  const handleVideoUpload = useCallback(
    async (file: File) => {
      if (!file.name) {
        console.error('Invalid file provided.');
        return;
      }

      resetState();

      mixpanel.track('Video Upload', {
        category: 'Video Processing',
        fileName: file.name,
      });

      const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
      const uploadTimeInSeconds = calculateUploadTime(fileSizeInMB);
      setUploadDuration(uploadTimeInSeconds);
      setIsUploading(true);

      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = async () => {
        URL.revokeObjectURL(video.src);
        const duration = video.duration;
        if (duration > 180) {
          setShowDurationWarning(true);
        }
      };

      try {
        const uploadParamsResponse = await getUploadParams(file.name);
        const { fileId, downloadUrl } = await uploadToS3({
          data: uploadParamsResponse.data,
          file,
        });
        setVideoInfo(fileId, downloadUrl);
        setUploadFinished(fileId).then((response) => {
          setProjectId(response.data.project_id);
          setProjectName(response.data.project_name);
        });
        handleUploadComplete();
      } catch (error) {
        console.error('Error during file upload:', error);
        setIsUploading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setVideoInfo, resetState, setShowDurationWarning]
  );

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  }, []);

  // Simplified drag handlers
  const handleDragOverEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        const file = e.dataTransfer.files[0];
        handleVideoUpload(file);
        const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
        const uploadTimeInSeconds = calculateUploadTime(fileSizeInMB);
        setUploadDuration(uploadTimeInSeconds);
      }
      setDragging(false);
    },
    [calculateUploadTime, handleVideoUpload]
  );

  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        handleVideoUpload(e.target.files[0]);
        const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
        console.log('fileSizeInMB: ', fileSizeInMB);
        const uploadTimeInSeconds = calculateUploadTime(fileSizeInMB);
        console.log('uploadTimeInSeconds: ', uploadTimeInSeconds);
        setUploadDuration(uploadTimeInSeconds);
      }
    },
    [handleVideoUpload]
  );

  const handleUploadComplete = useCallback(() => {
    setIsUploading(false);
    navigate('/app');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadProject = (projectId: number) => {
    loadProjectEndpoint(projectId).then((response) => {
      loadProjectPackage(response.data);
      setProjectId(projectId);
      navigate('/app');
    });
  };

  // Add click listener for closing the modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showDurationWarning &&
        !document.querySelector('.modal-warning-content').contains(event.target)
      ) {
        setShowDurationWarning(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showDurationWarning]);

  const ignore = (event) => {
    event.stopPropagation();
  };

  const openDeleteConfirmation = (event, projectId: number) => {
    event.stopPropagation();
    setProjectToDelete(projectId);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteProject = () => {
    if (projectToDelete !== null) {
      callDeleteProject(projectToDelete);
      setProjects((projects: array) => {
        return projects.filter((project: Record<string, any>) => project.id !== projectToDelete);
      });
      setShowDeleteConfirmation(false);
      setProjectToDelete(null);
    }
  };

  const cancelDeleteProject = () => {
    setShowDeleteConfirmation(false);
    setProjectToDelete(null);
  };

  return (
    <>
      <Head description="Adorno" />
      <div className="upload-page">
        <Header showProjectControls={false} />
        <div className="upload-page-container">
          <div className="upload-instructions-container">
            {!isUploading ? (
              <>
                {projects && (
                  <>
                    <br />
                    <h2 className="upload-heading">Existing Projects</h2>
                    <div className="example-video-thumbnails">
                      {projects.map((project: Record<string, any>) => (
                        <div
                          key={project.id}
                          onClick={() => loadProject(project.id)}
                          className="saved-project-block"
                          style={{
                            backgroundImage: 'url("' + project.thumbnail + '")',
                          }}
                        >
                          <div className="delete-button-container" onClick={ignore}>
                            <span
                              className="material-symbols-outlined delete-button"
                              title="Delete"
                              onClick={(event) => openDeleteConfirmation(event, project.id)}
                            >
                              delete
                            </span>
                          </div>
                          <p className="project-name" title="Open project">
                            {project.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </>
                )}

                <h2 className="upload-heading">
                  {projects
                    ? 'or start a new project'
                    : "Add your video and we'll start our analysis"}
                </h2>
                <div
                  onClick={() => fileInputRef.current?.click()}
                  onDragEnter={handleDragOverEnter}
                  onDragOver={handleDragOverEnter}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                  role="button"
                  tabIndex={0}
                  className={`file-dropzone ${dragging ? 'dragging' : ''}`}
                >
                  Drag and drop your video here, or click to select a file
                </div>

                {!projects && (
                  <div className="example-videos">
                    <h3>Or choose an example video:</h3>
                    <div className="example-video-thumbnails">
                      <video
                        src="/video1.mp4"
                        onClick={() => handleExampleVideoClick('/video1.mp4')}
                        className={selectedVideo?.name === 'example_video.mp4' ? 'selected' : ''}
                      />
                      <video
                        src="/video2.mp4"
                        onClick={() => handleExampleVideoClick('/video2.mp4')}
                        className={selectedVideo?.name === 'example_video.mp4' ? 'selected' : ''}
                      />
                      <video
                        src="/video3.mp4"
                        onClick={() => handleExampleVideoClick('/video3.mp4')}
                        className={selectedVideo?.name === 'example_video.mp4' ? 'selected' : ''}
                      />
                    </div>
                    <button onClick={handleContinueClick} disabled={!selectedVideo}>
                      Continue
                    </button>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="show-container">
                  <ProgressBar
                    startLoading={isUploading}
                    onComplete={handleUploadComplete}
                    uploadDuration={uploadDuration}
                  />
                </div>
              </>
            )}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: 'none' }}
              accept="video/*"
            />
          </div>
        </div>
        {showMobileWarning && (
          <div className="modal-overlay">
            <div className="modal-content">
              <p>
                The timeline editor interface isn't built for mobile.
                <br />
                We advise switching to desktop, but you can still continue here.
              </p>
              <div className="button-wrapper">
                <button onClick={() => setShowMobileWarning(false)}>Continue</button>
              </div>
            </div>
          </div>
        )}
        {showDurationWarning && (
          <div className="modal-warning-popup">
            <div className="modal-warning-content">
              <p>The video duration exceeds the 3-minute limit.</p>
              <button onClick={() => setShowDurationWarning(false)}>Close</button>
            </div>
          </div>
        )}
        {showDeleteConfirmation && (
          <div className="modal-overlay">
            <div className="modal-content">
              <p>
                Are you sure you want to delete this project?
                <br />
                This action is irreversible.
              </p>
              <div className="button-wrapper">
                <button onClick={confirmDeleteProject}>Delete</button>
                <button onClick={cancelDeleteProject}>Cancel</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
