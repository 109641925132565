import mixpanel from 'mixpanel-browser';
import React, { FC } from 'react';

import { useVideoContext } from '../context';

interface PromptBarProps {
  showBelow?: boolean; // Optional prop that defaults to false
}

export const PromptBar: FC<PromptBarProps> = ({ showBelow = false }) => {
  const { setPrompt, data, selectedCoords, setTStartSoundBox, setTEndSoundBox } = useVideoContext();
  const [inputText, setInputText] = React.useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const action = data[selectedCoords.row_i].actions[selectedCoords.action_i];
    setTStartSoundBox(action.start);
    setTEndSoundBox(action.end);
    setPrompt(inputText);

    mixpanel.track('Submit Prompt', {
      category: 'Prompt',
      prompt: inputText,
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };

  const belowClass = showBelow ? 'prompt-box-below' : '';
  const buttonText = inputText ? 'Submit' : 'No prompt';

  return (
    <>
      {showBelow && <div className={'promptBoxConnector arrow-up ' + belowClass}></div>}
      <form onSubmit={handleSubmit} className={'promptBox rounded ' + belowClass}>
        <label>
          <input
            type="text"
            placeholder="What would you like to hear?"
            autoFocus
            value={inputText}
            onChange={handleInputChange}
          />
        </label>
        {inputText ? (
          <button type="submit" className="rounded p-2 pl-3 pr-3">
            {buttonText}
          </button>
        ) : (
          <button type="button" onClick={handleSubmit} className="rounded p-2 pl-3 pr-3">
            {buttonText}
          </button>
        )}
      </form>
      {!showBelow && <div className="promptBoxConnector arrow-down"></div>}
    </>
  );
};
