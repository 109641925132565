import { axios } from '@/utils/axios';

export const callGetUserDetails = async (): Promise<void> => {
  // we leave data empty - user email gets populated automatically
  return await axios.post(`/users/account-detail/`, {});
};

export const callUnsubscribe = async (): Promise<void> => {
  // we leave data empty - user email gets populated automatically
  return await axios.post(`/api/unsubscribe/`, {});
};
