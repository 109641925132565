import mixpanel from 'mixpanel-browser';
import React, { useState } from 'react';

import { API_URL } from '@/config';
import { getConfig } from '@/config/config';
import { axios } from '@/utils/axios';

import { useVideoContext } from '../context';
import './styles.scss';

const { apiConfig } = getConfig();
const API_CONFIG = apiConfig;

export const VoiceBox: React.FC = () => {
  const [voiceText, setVoiceText] = useState('');
  const [selectedVoice, setSelectedVoice] = useState('');
  const [voiceUrl, setVoiceUrl] = useState<string | null>(null);
  const [voiceId, setVoiceId] = useState<number | null>(null);
  const [duration, setDuration] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { addNewAudio } = useVideoContext();
  // todo: implement subscription modal

  const handleVoiceTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setVoiceText(e.target.value);
  };

  const handleVoiceSelect = (voice: string) => {
    setSelectedVoice(voice);
  };

  const generateVoice = async () => {
    if (voiceText.length === 0 || selectedVoice === '') {
      return;
    }
    setIsLoading(true);
    const payload = {
      text: voiceText,
      voice: selectedVoice,
    };
    console.log('payload:', payload);
    try {
      const data = await axios.post(`${API_URL}/api/generate_voice/`, payload, API_CONFIG);
      setDuration(data['duration']);
      setVoiceUrl(data['presigned_url']);
      setVoiceId(data['id']);

      mixpanel.track('Generate Voice', {
        category: 'Voice',
        type: 'Generate Voice',
        voice: selectedVoice,
        text: voiceText,
      });
    } catch (error) {
      console.error('Error generating voice:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const addVoiceToTimeline = () => {
    addNewAudio({ id: voiceId, url: voiceUrl, text: voiceText, duration: duration, type: 'voice' });
    mixpanel.track('Add Voice to Timeline', {
      category: 'Voice',
      type: 'Add Voice to Timeline',
      voice: selectedVoice,
      text: voiceText,
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.code === 'Space') {
      event.preventDefault();
    }
  };

  const isGenerateButtonDisabled = isLoading || voiceText.length === 0 || selectedVoice === '';

  return (
    <div className="voiceBox">
      <h3>Speech Generation</h3>
      <div className="voiceTextArea">
        <textarea
          value={voiceText}
          onChange={handleVoiceTextChange}
          placeholder="Enter text to generate voice"
        ></textarea>
      </div>
      <div className="voiceOptions">
        <select value={selectedVoice} onChange={(e) => handleVoiceSelect(e.target.value)}>
          <option value="">Select Voice</option>
          <option value="alloy">Tam</option>
          <option value="echo">Luke</option>
          <option value="fable">Oliver</option>
          <option value="onyx">Nico</option>
          <option value="nova">Lily</option>
          <option value="shimmer">Jade</option>
        </select>
        <div className="characterCount">{voiceText.length} characters</div>
      </div>
      <button onClick={generateVoice} disabled={isGenerateButtonDisabled}>
        {isLoading ? 'Generating...' : 'Generate Voice'}
      </button>
      {voiceUrl && (
        <div className="voicePlayer">
          <audio src={voiceUrl} controls />
          <button
            className="addToTimelineButton"
            onClick={addVoiceToTimeline}
            onKeyDown={handleKeyDown}
          >
            Add to Timeline
          </button>
        </div>
      )}
    </div>
  );
};

export default VoiceBox;
