import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useEffect } from 'react';

import { Head } from '@/components/Head';
import Header from '@/pages/editor/header';
import { getUserPackage } from '@/pages/editor/header/api';

import { callGetUserDetails, callUnsubscribe } from './api';

import './account.scss';

export const AccountPage = () => {
  const { user } = useAuth0();
  const [userPlan, setUserPlan] = useState<string>('FREE');
  const [monthlyCost, setMonthlyCost] = useState<number>(0);
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState<boolean>(false);

  const refreshUserPrice = () => {
    callGetUserDetails().then((res) => {
      setMonthlyCost(res.price_per_month);
    });
  };

  const refreshUserPackage = () => {
    getUserPackage().then((response) => {
      setUserPlan((currentUserPlan) => {
        if (currentUserPlan !== response.package) {
          // if the user package changed, check the price
          // we try to avoid the call to the price because this makes 2 calls to stripe api as well
          // which takes server time
          refreshUserPrice();
        }
        return response.package;
      });

      // refresh their package every 20 seconds
      // ideally we should start this refresh on specific conditions, but this
      // is more reliable
      setTimeout(() => {
        refreshUserPackage();
      }, 20000); // 20 seconds
    });
  };

  useEffect(() => {
    refreshUserPrice();
    refreshUserPackage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openSubscriptionPage = () => {
    const paymentUrl = 'https://buy.stripe.com/dR64i5bzQgY6eNafYZ?prefilled_email=' + user.email;
    window.open(paymentUrl, '_blank', 'noopener,noreferrer');
  };

  const unsubscribe = () => {
    callUnsubscribe();
    showUnsubscribeModal(false);
    refreshUserPackage();
  };

  return (
    <>
      <Head description="Adorno" />
      <div className="account-page">
        <Header showProjectControls={false} showBackToUpload={true} />
        <div className="account-page-container">
          <div className="account-card">
            <h2 className="heading">Account Details</h2>
            <br />
            <table>
              <tr>
                <th>Email</th>
                <td>{user.email}</td>
              </tr>
              <tr>
                <th>Plan</th>
                <td>{userPlan}</td>
              </tr>
              <tr>
                <th>Monthly subscription</th>
                <td>${monthlyCost}.00</td>
              </tr>
            </table>
            <br /> <br />
            <div className="text-center">
              {userPlan === 'FREE' ? (
                <>
                  <button onClick={openSubscriptionPage}>Start 7-day free trail</button>
                  <br />
                  <br />
                  <p>Get unlimited sound generation - SFX, voice and music.</p>
                </>
              ) : (
                <button onClick={() => setShowUnsubscribeModal(true)}>Cancel subscription</button>
              )}
            </div>
          </div>
        </div>
      </div>
      {showUnsubscribeModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p className="new-project-message">Unsubscribe from the {userPlan} plan?</p>
            <div className="button-wrapper">
              <button onClick={() => setShowUnsubscribeModal(false)}>Back</button>
              <button onClick={unsubscribe}>Unsubscribe</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
