import axios from 'axios';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

import { API_URL } from '@/config';
import { getConfig } from '@/config/config';

const { apiConfig } = getConfig();
const API_CONFIG = apiConfig;

export const handleExportAudio = async (setData: any[]): Promise<void> => {
  const zip = new JSZip();

  // Filter actions to include only those with effectId 'audio'
  const audioActions = setData
    .flatMap((item) => item.actions)
    .filter(
      (action) =>
        action.effectId === 'audio' || action.effectId === 'voice' || action.effectId === 'music'
    );

  await Promise.all(
    audioActions.map(async (action: any, index: number) => {
      try {
        const response = await fetch(action.data.src);
        if (!response.ok) {
          throw new Error(`Failed to fetch ${action.data.src}. Status: ${response.status}`);
        }
        const blob = await response.blob();
        const sanitizedTitle = action.data.name.replace(/\s+/g, '_');
        // todo: needs to be adaptable
        const extension = getExtension(action.data.src);
        const filename = `${sanitizedTitle}_${action.effectId}_${index}.` + extension;
        zip.file(filename, blob, { binary: true });
      } catch (error) {
        if (error instanceof Error) {
          // Type guard to narrow down 'error' from 'unknown' to 'Error'
          console.error(`Error fetching or adding file: ${error.message}`);
        }
      }
    })
  );

  const content = await zip.generateAsync({ type: 'blob' });
  saveAs(content, 'audio_files.zip');
};

export const initiateDownload = async (url: string, filename: string): Promise<void> => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch ${url}. Status: ${response.status}`);
    }
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove(); // Updated for clarity
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    const message = (error as Error).message;
    console.error(`Error fetching or adding file: ${message}`);
  }
};

const getExtension = (url: string): string => {
  const splits = url.split('?', 1)[0].split('.');
  return splits[splits.length - 1];
};

export const exportMixedAudio = async (setData: any[]): Promise<void> => {
  // Extract video and audio info from setData
  const videoInfo = setData
    .flatMap((item) => item.actions)
    .find((action) => action.effectId === 'video');
  const videoURL = videoInfo ? videoInfo.data.src : '';
  console.log('videoURL:', videoURL);
  const videoId = videoInfo ? videoInfo.id.toString() : '';
  console.log('videoId:', videoId);

  // Prepare the audioTracksInfo payload
  const audioTracksInfo = setData
    .flatMap((item) => item.actions)
    .filter(
      (action) =>
        action.effectId === 'audio' || action.effectId === 'voice' || action.effectId === 'music'
    )
    .map((action) => ({
      src: action.data.src,
      startTime: action.start,
      endTime: action.end,
    }));

  console.log('audioTracksInfo:', audioTracksInfo);
  // Construct the payload for the API request
  const payload = {
    video_id: videoId,
    video_url: videoURL,
    audio_tracks_info: audioTracksInfo,
  };

  console.log('payload:', payload);

  try {
    const response = await axios.post(`${API_URL}/api/export_mixed_track/`, payload, API_CONFIG);
    const presignedUrl = response.data.mixed_track_url;
    if (presignedUrl) {
      console.log('presignedUrl:', presignedUrl);
      const extension = getExtension(presignedUrl);
      initiateDownload(presignedUrl, 'mixed_track.' + extension);
    } else {
      console.error('No download URL received in response');
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      // Log detailed error information
      console.error('Error during mixed audio export:', error.response.data);
    } else {
      console.error('Error during mixed audio export:', error);
    }
  }
};

export const exportVideoWithMixedAudio = async (setData: any[]): Promise<void> => {
  // Extract video and audio info from setData
  const videoInfo = setData
    .flatMap((item) => item.actions)
    .find((action) => action.effectId === 'video');
  const videoURL = videoInfo ? videoInfo.data.src : '';
  console.log('videoURL:', videoURL);
  const videoId = videoInfo ? videoInfo.id.toString() : '';
  console.log('videoId:', videoId);

  // Prepare the audioTracksInfo payload
  const audioTracksInfo = setData
    .flatMap((item) => item.actions)
    .filter(
      (action) =>
        action.effectId === 'audio' || action.effectId === 'voice' || action.effectId === 'music'
    )
    .map((action) => ({
      src: action.data.src,
      startTime: action.start,
      endTime: action.end,
    }));

  console.log('audioTracksInfo:', audioTracksInfo);
  // Construct the payload for the API request
  const payload = {
    video_id: videoId,
    video_url: videoURL,
    audio_tracks_info: audioTracksInfo,
  };

  console.log('payload:', payload);
  try {
    const response = await axios.post(
      `${API_URL}/api/export_video_and_audio/`,
      payload,
      API_CONFIG
    );
    const presignedUrl = response.data.video_url;
    if (presignedUrl) {
      const extension = getExtension(presignedUrl);
      initiateDownload(presignedUrl, 'video_with_mixed_audio.' + extension);
    } else {
      console.error('No download URL received in response');
    }
  } catch (error: any) {
    // Handling the error as any to access its properties
    console.error('Error during exporting video with mixed audio:', error.message);
  }
};
