// index.tsx
import mixpanel from 'mixpanel-browser';
import React, { useState, useCallback, useRef, useEffect } from 'react';

import ProgressBar from '@/components/Progressbar';
import { useVideoContext } from '@/pages/editor/context';
import { loadProjectEndpoint } from '@/pages/videoUpload/api';

import { getUploadParams, setUploadFinished, uploadToS3 } from '../api';

import ChatModal from './ChatModal';

import './storyBlock.scss';

const HYPOTHETICAL_UPLOAD_SPEED_MBPS = 5; // Hypothetical upload speed in Megabits per second

export const StoryBlock = ({
  blockId,
  updateBlock,
  setLastFilledDer,
}: {
  blockId: number;
  updateBlock: (blockId: number, content: string) => void;
  setLastFilledDer: (blockId: number) => void;
}) => {
  const blockVideoRef = useRef(null);

  const { videoRef, setProjectId, setProjectName, loadProjectPackage, projectId, setVideoInfo } =
    useVideoContext();
  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  const [dragging, setDragging] = useState(false);
  const [uploadDuration, setUploadDuration] = useState(0);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [showDurationWarning, setShowDurationWarning] = useState(false);
  const [showMobileWarning, setShowMobileWarning] = useState(true);
  const [showChatModal, setShowChatModal] = useState(false);
  const [comments, setComments] = useState<Comment[]>([]);

  useEffect(() => {
    const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (event.key === 'Escape') {
        setShowDurationWarning(false);
      }
    };

    document.addEventListener('keydown', handleClose);
    return () => document.removeEventListener('keydown', handleClose);
  }, []);

  useEffect(() => {
    const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const isSmallScreen = window.innerWidth <= 720;
    if (!isMobileDevice && !isSmallScreen) {
      setShowMobileWarning(false);
    }
  }, []);

  // fixme: react thinks this might cause an infinite loop
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculateUploadTime = (fileSizeInMB: number) => {
    const uploadSpeedMBps = HYPOTHETICAL_UPLOAD_SPEED_MBPS / 8; // Convert Mbps to MBps
    return fileSizeInMB / uploadSpeedMBps; // Returns time in seconds
  };

  const handleVideoUpload = useCallback(
    async (file: File) => {
      if (!file.name) {
        console.error('Invalid file provided.');
        return;
      }

      mixpanel.track('Video Upload', {
        category: 'Video Processing',
        fileName: file.name,
      });

      const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
      const uploadTimeInSeconds = calculateUploadTime(fileSizeInMB) + 2;
      setUploadDuration(uploadTimeInSeconds);
      setIsUploading(true);
      setLastFilledDer(blockId);

      try {
        const uploadParamsResponse = await getUploadParams(file.name);
        const { fileId, downloadUrl } = await uploadToS3({
          data: uploadParamsResponse.data,
          file,
        });

        setUploadFinished(fileId).then((response) => {
          setVideoUrl(downloadUrl);
          updateBlock(blockId, videoUrl, response.data.project_id);
          setProjectId(response.data.project_id);
          setProjectName('TMP SCENE');

          blockVideoRef.current.onloadedmetadata = () => {
            videoRef.current = blockVideoRef.current;
            setVideoInfo(fileId, downloadUrl);
            setIsUploading(false);
            // perhaps a delay here?
          };
        });
      } catch (error) {
        console.error('Error during file upload:', error);
        setIsUploading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setShowDurationWarning]
  );

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  }, []);

  // Simplified drag handlers
  const handleDragOverEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        const file = e.dataTransfer.files[0];
        handleVideoUpload(file);
        const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
        const uploadTimeInSeconds = calculateUploadTime(fileSizeInMB);
        setUploadDuration(uploadTimeInSeconds);
      }
      setDragging(false);
    },
    [calculateUploadTime, handleVideoUpload]
  );

  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        handleVideoUpload(e.target.files[0]);
        const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
        console.log('fileSizeInMB: ', fileSizeInMB);
        const uploadTimeInSeconds = calculateUploadTime(fileSizeInMB);
        console.log('uploadTimeInSeconds: ', uploadTimeInSeconds);
        setUploadDuration(uploadTimeInSeconds);
      }
    },
    [handleVideoUpload]
  );

  // Add click listener for closing the modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showDurationWarning &&
        !document.querySelector('.modal-warning-content').contains(event.target)
      ) {
        setShowDurationWarning(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showDurationWarning]);

  const openInNewTab = useCallback((url: string, params: Record<string, any> | null) => {
    if (!params) {
      params = {};
    }
    const urlWithParams = `${url}?${new URLSearchParams(params).toString()}`;
    window.open(urlWithParams, '_blank', 'noopener,noreferrer');
  }, []);

  const openProject = () => {
    loadProjectEndpoint(projectId).then((response) => {
      loadProjectPackage(response.data);
      openInNewTab('/app', { projectId: projectId });
    });
  };

  return (
    <div className={`story-block ${videoUrl ? 'has-video' : ''}`}>
      {videoUrl && (
        <div className="video-container">
          <div className="center-container">
            <div className="button-container">
              <div className="open-button" onClick={openProject}>
                Open project
              </div>
              <div className="open-button" onClick={() => setShowChatModal(true)}>
                Open chat
              </div>
            </div>
          </div>
          <video ref={blockVideoRef} src={videoUrl} controls></video>
        </div>
      )}

      {!isUploading && !videoUrl && (
        <div
          onClick={() => fileInputRef.current?.click()}
          onDragEnter={handleDragOverEnter}
          onDragOver={handleDragOverEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          role="button"
          tabIndex={0}
          className={`file-dropzone ${dragging ? 'dragging' : ''}`}
        >
          <div className="plus-sign">+</div>
        </div>
      )}

      {isUploading && (
        <div className="loading-container">
          <ProgressBar startLoading={isUploading} uploadDuration={uploadDuration} />
        </div>
      )}

      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        accept="video/*"
      />

      {showMobileWarning && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>
              The timeline editor interface isn't built for mobile.
              <br />
              We advise switching to desktop, but you can still continue here.
            </p>
            <div className="button-wrapper">
              <button onClick={() => setShowMobileWarning(false)}>Continue</button>
            </div>
          </div>
        </div>
      )}

      {showDurationWarning && (
        <div className="modal-warning-popup">
          <div className="modal-warning-content">
            <p>The video duration exceeds the 3-minute limit.</p>
            <button onClick={() => setShowDurationWarning(false)}>Close</button>
          </div>
        </div>
      )}
      {showChatModal && (
        <ChatModal
          videoUrl={videoUrl}
          onClose={() => setShowChatModal(false)}
          comments={comments}
          setComments={setComments}
        />
      )}
    </div>
  );
};
