import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Head } from '@/components/Head';
import { getUserPackage } from '@/pages/editor/header/api';
import logoImg from '@/pages/editor/header/logo.png';

import Beatles from './beatles.mp4';
import ProjectChatModal from './ProjectChatModal';
import { StoryBlock } from './storyBlock';

import './storyline.scss';

interface Collaborator {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

export const StorylinePage = () => {
  const { user } = useAuth0();
  const userEmail = user?.email;
  const userInitial = userEmail ? userEmail[0].toUpperCase() : '';
  const [pricingPackage, setPricingPackage] = useState<string | null>('FREE');
  const [lastFilled, setLastFilled] = useState<number | null>(null);
  const [storyBlocks, setStoryBlocks] = useState<
    Array<{
      title: string;
      blocks: Array<{ blockId: number; videoUrl: string | null; projectId: string | null }>;
    }>
  >([
    {
      title: '',
      blocks: [
        {
          blockId: 1,
          videoUrl: null,
          projectId: null,
        },
      ],
    },
  ]);
  const [showMobileWarning, setShowMobileWarning] = useState(true);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [collaborators, setCollaborators] = useState<Collaborator[]>([
    { id: 1, firstName: 'Alex', lastName: 'Berkovic', email: 'alex@adorno.ai', role: 'Owner' },
    {
      id: 2,
      firstName: 'Chris',
      lastName: 'Wust',
      email: 'chris@adorno.ai',
      role: 'Editor',
    },
  ]);
  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const shareDropdownRef = useRef<HTMLDivElement>(null);
  const [showAddCollaboratorModal, setShowAddCollaboratorModal] = useState(false);
  const [newCollaborator, setNewCollaborator] = useState<Collaborator>({
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    role: 'Viewer',
  });
  const [versions, setVersions] = useState<string[]>(['1.0', '1.1', '2.0']);
  const [selectedVersion, setSelectedVersion] = useState('1.0');
  const [showExportModal, setShowExportModal] = useState(false);
  const [showSaveProjectModal, setShowSaveProjectModal] = useState(false);
  const [newVersionName, setNewVersionName] = useState('');
  const [showVersionChangeModal, setShowVersionChangeModal] = useState(false);
  const [newSelectedVersion, setNewSelectedVersion] = useState('');
  const [showProjectChatModal, setShowProjectChatModal] = useState(false);
  const [comments, setComments] = useState<Comment[]>([]);

  const openSaveProjectModal = () => {
    setShowSaveProjectModal(true);
  };

  const closeSaveProjectModal = () => {
    setShowSaveProjectModal(false);
    setNewVersionName('');
  };

  useEffect(() => {
    getUserPackage().then((response) => {
      setPricingPackage(response.package);
    });
  }, []);

  const handleVersionChange = (shouldSave: boolean) => {
    if (shouldSave) {
      setSelectedVersion(newSelectedVersion);
      setShowVersionChangeModal(false);
      setShowSaveProjectModal(false);
    } else {
      setSelectedVersion(newSelectedVersion);
      setShowVersionChangeModal(false);
    }
  };

  const handleVersionChangeClick = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newVersion = event.target.value;
    setNewSelectedVersion(newVersion);
    setShowVersionChangeModal(true);
  };

  const openVideoModal = () => {
    setShowVideoModal(true);
    document.body.style.overflow = 'hidden';
  };

  const closeVideoModal = () => {
    setShowVideoModal(false);
    document.body.style.overflow = 'auto';
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      closeVideoModal();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeVideoModal();
      }
    };

    if (showVideoModal) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showVideoModal]);

  const setLastFilledDer = (id: number) => {
    setLastFilled(id);
  };

  const createEmptyBlock = () => {
    return {
      title: '',
      blocks: [
        {
          blockId: Math.floor(Math.random() * 1_000_000),
          videoUrl: null,
          projectId: null,
        },
      ],
    };
  };

  useEffect(() => {
    const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const isSmallScreen = window.innerWidth <= 720;
    if (!isMobileDevice && !isSmallScreen) {
      setShowMobileWarning(false);
    }
  }, []);

  useEffect(() => {
    if (lastFilled === null) {
      return;
    }

    console.log('lastFilled ', lastFilled);

    setStoryBlocks((currentStoryBlocks) => {
      const newStoryBlocks = [...currentStoryBlocks];
      let foundBlock = false;

      for (let i = 0; i < newStoryBlocks.length; i++) {
        const column = newStoryBlocks[i];
        for (let j = 0; j < column.blocks.length; j++) {
          const block = column.blocks[j];
          if (block.blockId === lastFilled) {
            foundBlock = true;

            if (j === column.blocks.length - 1) {
              // If last block in the column
              column.blocks.push(createEmptyBlock().blocks[0]);
            }

            if (i === newStoryBlocks.length - 1) {
              // If last block in the last column
              newStoryBlocks.push(createEmptyBlock());
            }

            break;
          }
        }
        if (foundBlock) {
          break;
        }
      }

      if (!foundBlock) {
        // If the last filled block is not found, create a new block in the first column
        newStoryBlocks[0].blocks.push(createEmptyBlock().blocks[0]);
      }

      return newStoryBlocks;
    });
  }, [lastFilled]);

  const updateBlock = (blockId: number, videoUrl: string, projectId: number) => {
    console.log('blockId ' + blockId);
    const indices = getBlockIndex(blockId);
    if (indices !== null) {
      const [i, j] = indices;
      const block = storyBlocks[i].blocks[j];
      block.videoUrl = videoUrl;
      block.projectId = projectId;
    } else {
      console.log(`Block with ID ${blockId} is not found`);
      // Handle the case when the block is not found
    }
  };

  const getBlockIndex = (blockId: number): [number, number] | null => {
    for (let i = 0; i < storyBlocks.length; i++) {
      const column = storyBlocks[i];
      for (let j = 0; j < column.blocks.length; j++) {
        if (column.blocks[j].blockId === blockId) {
          return [i, j];
        }
      }
    }
    return null;
  };

  const handleColumnTitleChange = (columnIndex: number, newTitle: string) => {
    setStoryBlocks((prevStoryBlocks) => {
      const updatedStoryBlocks = [...prevStoryBlocks];
      updatedStoryBlocks[columnIndex].title = newTitle;
      return updatedStoryBlocks;
    });
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    if (source.droppableId === destination.droppableId) {
      const columnIndex = parseInt(source.droppableId);
      const newBlocks = Array.from(storyBlocks[columnIndex].blocks);
      const [removed] = newBlocks.splice(source.index, 1);
      newBlocks.splice(destination.index, 0, removed);

      setStoryBlocks((prevStoryBlocks) => {
        const updatedStoryBlocks = [...prevStoryBlocks];
        updatedStoryBlocks[columnIndex].blocks = newBlocks;
        return updatedStoryBlocks;
      });
    }
  };

  const toggleShareDropdown = () => {
    setShowShareDropdown(!showShareDropdown);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (shareDropdownRef.current && !shareDropdownRef.current.contains(event.target as Node)) {
      setShowShareDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const openAddCollaboratorModal = () => {
    setShowAddCollaboratorModal(true);
  };

  const closeAddCollaboratorModal = () => {
    setShowAddCollaboratorModal(false);
    setNewCollaborator({
      id: 0,
      firstName: '',
      lastName: '',
      email: '',
      role: 'Viewer',
    });
  };

  const handleNewCollaboratorChange = (field: keyof Collaborator, value: string) => {
    setNewCollaborator((prevCollaborator) => ({
      ...prevCollaborator,
      [field]: value,
    }));
  };

  const addCollaborator = () => {
    const { firstName, lastName, email, role } = newCollaborator;
    if (firstName && lastName && email && role) {
      const newCollaboratorWithId: Collaborator = {
        ...newCollaborator,
        id: collaborators.length + 1,
      };
      setCollaborators([...collaborators, newCollaboratorWithId]);
      closeAddCollaboratorModal();
    }
  };

  const updateCollaboratorRole = (id: number, role: string) => {
    const updatedCollaborators = collaborators.map((collaborator) =>
      collaborator.id === id ? { ...collaborator, role } : collaborator
    );
    setCollaborators(updatedCollaborators);
  };

  const exportVideo = (option) => {
    if (option === 'full') {
      const link = document.createElement('a');
      link.href = Beatles;
      link.download = 'preview.mp4';
      link.click();
    } else if (option === 'blocks') {
      // Add logic to export story blocks individually
      console.log('Exporting story blocks individually');
    }
    setShowExportModal(false);
  };

  const shouldDisplayConnector = (blockId: number) => {
    // @ts-ignore
    const [i, j] = getBlockIndex(blockId);

    return j === 0 && i < storyBlocks.length - 1;
  };

  const handleSaveProject = () => {
    if (newVersionName) {
      // Add logic to save the current project with the new version name
      console.log(`Saving current project as version: ${newVersionName}`);
      // Update the versions array with the new version
      setVersions([...versions, newVersionName]);
      // Update the selectedVersion state with the new version name
      setSelectedVersion(newVersionName);
      closeSaveProjectModal();
    }
  };

  return (
    <>
      <Head description="Adorno" />
      <div className={`story-page ${showVideoModal ? 'blurred' : ''}`}>
        <div className="header">
          <div className="header-left">
            <div className="logo-and-user-info">
              <div className="logo-box">{userInitial}</div>
              <div className="user-info">
                <div className="user-name">{userEmail}</div>
                <div className="membership-status">{pricingPackage} PACKAGE</div>
              </div>
            </div>
          </div>
          {window.innerWidth > 1200 && (
            <div className="header-center">
              <img src={logoImg} alt="Logo" className="logo-image" />
            </div>
          )}
          <div className="header-right">
            <div className="version-container action-button no-hover">
              <h2>Version:</h2>
              <select
                id="version-select"
                value={selectedVersion}
                onChange={handleVersionChangeClick}
              >
                {versions.map((version) => (
                  <option key={version} value={version}>
                    {version}
                  </option>
                ))}
              </select>
            </div>
            <button className="save-project-button action-button" onClick={openSaveProjectModal}>
              <h2>
                <span className="material-symbols-outlined">save</span>
                Save Project
              </h2>
            </button>
            <div className="share-container action-button" ref={shareDropdownRef}>
              <button onClick={toggleShareDropdown}>
                <h2>
                  <span className="material-symbols-outlined">group</span>
                  Invite Team
                </h2>
              </button>
              {showShareDropdown && (
                <div className="share-dropdown">
                  <h3>Collaborators</h3>
                  {collaborators.map((collaborator) => (
                    <div key={collaborator.id} className="collaborator-item">
                      <span>
                        <h4>
                          {collaborator.firstName} {collaborator.lastName}
                        </h4>
                      </span>
                      <select
                        value={collaborator.role}
                        onChange={(e) => updateCollaboratorRole(collaborator.id, e.target.value)}
                      >
                        <option value="Owner">Owner</option>
                        <option value="Editor">Editor</option>
                        <option value="Viewer">Viewer</option>
                      </select>
                    </div>
                  ))}
                  <button className="add-collaborator-button" onClick={openAddCollaboratorModal}>
                    Add Collaborator
                  </button>
                </div>
              )}
            </div>
            <button
              className="action-button action-button"
              onClick={() => setShowExportModal(true)}
            >
              <h2>
                <span className="material-symbols-outlined">download</span>
                Export
              </h2>
            </button>
          </div>
        </div>

        <div className="upload-instructions-container">
          <div className="upload-heading-row">
            <h2 className="upload-heading" contentEditable={true}>
              Create Your Storyline
            </h2>
          </div>
          <div className="content-row">
            <p contentEditable={true}>Drag and drop your videos to build an engaging narrative</p>
            <button className="project-chat-button" onClick={() => setShowProjectChatModal(true)}>
              Project Chat
            </button>
            <button className="preview-button" onClick={openVideoModal}>
              Preview Video
            </button>
          </div>
        </div>

        <div className="story-page-container">
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="story-blocks-wrapper">
              {storyBlocks.map((column, columnIndex) => (
                <React.Fragment key={columnIndex}>
                  <div className="story-column">
                    <div className="column-title">
                      <input
                        type="text"
                        value={column.title || ''}
                        onChange={(e) => handleColumnTitleChange(columnIndex, e.target.value)}
                        placeholder="Enter column title"
                      />
                    </div>
                    <Droppable droppableId={columnIndex.toString()}>
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {column.blocks.map((block, index) => (
                            <Draggable
                              key={block.blockId}
                              draggableId={block.blockId.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`${
                                    index === 0 ? 'first-block' : ''
                                  } storyblock-wrapper`}
                                >
                                  <StoryBlock
                                    key={block.blockId}
                                    blockId={block.blockId}
                                    updateBlock={updateBlock}
                                    setLastFilledDer={setLastFilledDer}
                                    videoUrl={block.videoUrl}
                                  />
                                  {shouldDisplayConnector(block.blockId) && (
                                    <div className="storyblock-connector"></div>
                                  )}
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                  {columnIndex < storyBlocks.length - 1 && <div className="column-separator"></div>}
                </React.Fragment>
              ))}
            </div>
          </DragDropContext>
        </div>

        {showMobileWarning && (
          <div className="modal-overlay">
            <div className="modal-content">
              <p>
                The timeline editor interface isn't built for mobile.
                <br />
                We advise switching to desktop, but you can still continue here.
              </p>
              <div className="button-wrapper">
                <button onClick={() => setShowMobileWarning(false)}>Continue</button>
              </div>
            </div>
          </div>
        )}

        {showVideoModal && (
          <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <video ref={videoRef} className="modal-video" controls>
                <source src={Beatles} type="video/mp4" />
              </video>
              <button className="escape-preview-button" onClick={closeVideoModal}>
                Escape Preview
              </button>
            </div>
          </div>
        )}

        {showAddCollaboratorModal && (
          <div className="add-collaborator-modal">
            <div className="add-collaborator-modal-content">
              <h3>Add Collaborator</h3>
              <input
                type="text"
                placeholder="First Name"
                value={newCollaborator.firstName}
                onChange={(e) => handleNewCollaboratorChange('firstName', e.target.value)}
              />
              <input
                type="text"
                placeholder="Last Name"
                value={newCollaborator.lastName}
                onChange={(e) => handleNewCollaboratorChange('lastName', e.target.value)}
              />
              <input
                type="email"
                placeholder="Email"
                value={newCollaborator.email}
                onChange={(e) => handleNewCollaboratorChange('email', e.target.value)}
              />
              <select
                value={newCollaborator.role}
                onChange={(e) => handleNewCollaboratorChange('role', e.target.value)}
              >
                <option value="Owner">Owner</option>
                <option value="Editor">Editor</option>
                <option value="Viewer">Viewer</option>
              </select>
              <div className="modal-buttons">
                <button onClick={closeAddCollaboratorModal}>Cancel</button>
                <button onClick={addCollaborator}>Add</button>
              </div>
            </div>
          </div>
        )}
        {showExportModal && (
          <div className="modal-overlay">
            <div className="modal-content export-modal">
              <h3>Export Options</h3>
              <div className="export-options">
                <button onClick={() => exportVideo('blocks')}>Story Blocks Individually</button>
                <button onClick={() => exportVideo('full')}>Full Video</button>
              </div>
              <button className="cancel-button" onClick={() => setShowExportModal(false)}>
                Cancel
              </button>
            </div>
          </div>
        )}
        {showSaveProjectModal && (
          <div className="modal-overlay">
            <div className="modal-content save-project-modal">
              <h3>Save Project</h3>
              <input
                type="text"
                placeholder="Enter version name"
                value={newVersionName}
                onChange={(e) => setNewVersionName(e.target.value)}
              />
              <div className="modal-buttons">
                <button onClick={closeSaveProjectModal}>Cancel</button>
                <button onClick={handleSaveProject}>Save</button>
              </div>
            </div>
          </div>
        )}
        {showVersionChangeModal && (
          <div className="modal-overlay">
            <div className="modal-content version-change-modal">
              <h3>Change Version</h3>
              <p>Do you want to save the current work before changing the version?</p>
              <div className="modal-buttons">
                <button
                  className="secondary-button"
                  onClick={() => {
                    setShowVersionChangeModal(false);
                    setNewSelectedVersion('');
                  }}
                >
                  Cancel
                </button>
                <button className="primary-button" onClick={() => handleVersionChange(true)}>
                  Save and Change
                </button>
                <button className="tertiary-button" onClick={() => handleVersionChange(false)}>
                  Change without Saving
                </button>
              </div>
            </div>
          </div>
        )}
        {showProjectChatModal && (
          <ProjectChatModal
            onClose={() => setShowProjectChatModal(false)}
            comments={comments}
            setComments={setComments}
          />
        )}
      </div>
    </>
  );
};
